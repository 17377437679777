import React from 'react'

export default function Whitepaperscomponent(props) {

  return (
    <div
      className="flex flex-col items-center justify-center border-2 border-gray-100 md:px-3 md:py-3 px-2 py-1 md:rounded-2xl rounded-md bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef]  md:w-[25rem] md:h-[30rem]  xl:w-[30rem] xl:h-[15rem] 2xl:w-[40rem] 2xl:h-[20rem] w-[22rem] h-[24rem]"
    >
      <div className="flex sm:flex-row flex-col items-center  sm:justify-center md:gap-10 gap-3">



        <div className='flex-col items-center justify-center sm:block hidden'>
          <div className='w-full px-[0.9rem] flex flex-wrap font-opensans font-normal text-[#000000] text-justify text-xs 2xl:text-base'>
            {props.content}
          </div>
          <div className='w-full  items-center  justify-start pl-2 sm:block hidden'>
            <div onClick={() => props.onReadMoreClicked(props.id)} className=' xl:w-1/3 md:w-1/2 flex items-center justify-center cursor-pointer bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] px-2 py-2 rounded-lg hover:bg-[#EBF2F8] text-xs 2xl:text-base'>
              Read more

            </div>
          </div>

        </div>
        <img src={props.img} className='w-[9rem] h-[11rem]  object-fill sm:block hidden'></img>
        <img src={props.img} className='w-[9rem] h-[11rem]  object-fill sm:hidden block'></img>
        <div className='flex-col items-center justify-center sm:hidden block'>
          <div className='w-full px-[0.9rem] flex flex-wrap font-opensans font-normal text-[#000000] text-justify text-xs 2xl:text-base'>
            {props.content}
          </div>
          <div className='w-full flex items-center  justify-center pl-2 sm:hidden block'>
            <div onClick={() => props.onReadMoreClicked(props.id)} className=' w-1/3  flex items-center justify-center cursor-pointer bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] px-2 py-2 rounded-lg hover:bg-[#EBF2F8] text-xs 2xl:text-base'>
              Read more

            </div>
          </div>

        </div>

      </div>
    </div>
  )
}
