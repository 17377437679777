import React,{useState} from 'react'
import "./../App.css"

export default function Bigboxcomponent(props) {
    const [isHovered, setIsHovered] = useState(false);
  return (
   
       <div
       
       className={`w-[15rem] h-[19rem] xl:w-[20rem] xl:h-[23rem]  rounded-[1rem] lg:rounded-[2rem] flex flex-col items-center justify-center gap-2 gap- px-2 py-3 border-2 border-gray-100 bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] hover:bg-[#EBF2F8]`}
       onMouseEnter={() => setIsHovered(true)}
       onMouseLeave={() => setIsHovered(false)}
     >
       <img src={props.img} alt={props.name} className={`xl:w-[6.2rem] xl:h-[5.8rem] w-[3rem] h-[3rem] object-fill  ${isHovered ? 'xl:w-[4rem] xl:h-[3.6rem]' : ''}`} />
       <div>

      <div className={`h-auto text-center wordGradient bottom-border-gradient font-black font-opensans text-sm xl:text-[1.1rem]  py-2 ${isHovered ? 'xl:text-[1rem] ' : ''}`}>{props.name}</div>
      
      </div>
       <p className={`text-[#000000]  font-opensans font-normal text-xs md:text-sm text-justify w-[80%] h-[40%]
       ${isHovered ? 'xl:text-[0.85rem]' : ''}`}>
         {props.content}
       </p>
       
     </div>
   );
  
  
}
