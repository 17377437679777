import React, { useState, useRef , useEffect,useLayoutEffect} from 'react'
import Boxcomponent from './Bigboxcomponent';
import { aboutusdata } from '../Data/Aboutus';
import Bigboxcomponent from './Bigboxcomponent';


export default function Aboutuscontainer() {

    return (
        <div  className="relative w-full">
      <div  
        className="flex flex-wrap  xl:max-w-[90%]  mx-auto items-center justify-center gap-4 md:px-10 md:py-10 px-3 py-5 cursor-pointer"
        
      >
        {aboutusdata.map((item) => (
          <div   key={item.id} >   
            <Bigboxcomponent
            id={item.id}
              img={item.img}
              name={item.name}
              content={item.content}
            />
          </div>
        ))}
      </div>
    </div>
      );
}
