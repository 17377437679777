import React, { useState } from 'react'
import "./../App.css"

export default function Boxcomponent(props) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div

      className={`w-[15rem] h-[18rem]  border-2 border-gray-100 rounded-[2rem] flex flex-col  items-center justify-center  gap-2  px-2 py-3 bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] hover:bg-[#EBF2F8]`}   onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={props.img} alt={props.name} className={` w-[3.5rem] h-[3.5rem]  object-fill ${isHovered ? 'xl:w-[3.2rem] xl:h-[3.2rem]' : ''}`} />
      <div>

      <div className={`h-auto text-center wordGradient bottom-border-gradient font-black font-opensans text-[1.1rem] py-2 ${isHovered ? 'text-[1rem]' : ''} `}>{props.name}</div>
      
      </div>
       <p className={`text-[#000000]  font-opensans font-normal text-xs  text-justify w-[80%] h-[40%]${isHovered ? 'xl:text-[0.9rem]' : ''}`}>
         {props.content}
       </p>

    </div>
  );
}
