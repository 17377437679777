import React, { useState, useRef , useEffect,useLayoutEffect} from 'react'
import Logocomponent from './Logocomponent'
import { logodata } from '../Data/Partners';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function Partnerslogocontainer() {
  const partnersRef = useRef(null);
  const partnersContainerRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(partnersContainerRef.current, {
      opacity: 0,
      y: 50,
    }, {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: partnersRef.current,
        start: "top bottom-=200",
        toggleActions: "play none none none"
      }
    });
  }, []); 
  return (
    <div ref={partnersRef} className='w-full flex flex-col items-center justify-center gap-2 py-10 '>
      <div className='font-opensansbold font-bold text-[#3b84b4] text-2xl 2xl:text-3xl'>Partners

      </div>
      <div className='sm:w-[60%] w-[80%] font-opensans font-medium text-sm 2xl:text-lg text-[#000000] text-justify'>We have established valuable partnerships with a wide range of industry experts, enabling us to deliver exceptional solutions to our clients by leveraging their expertise and innovative approaches.</div>

      <div className="relative w-full">
        <div
         ref={partnersContainerRef}  className="flex flex-wrap max-w-full mx-auto items-center justify-center gap-5  md:py-8 py-5 cursor-pointer"

        >
          {logodata.map((item) => (
            <div key={item.id} >
              <Logocomponent
                id={item.id}
                img={item.img}
              />
            </div>
          ))}
        </div>
      </div>

    </div>
  )
}
