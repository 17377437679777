import React from 'react'
import Boxcomponent from './Boxcomponent';
import { benefitservicesdata } from '../Data/Services';

export default function BenefitsContainer() {
   
        return (
            <div className="relative w-full">
          <div
            className="flex flex-wrap  xl:max-w-[90%]  mx-auto items-center justify-center gap-4 md:px-10 md:py-10 px-3 cursor-pointer"
          >
            {benefitservicesdata.map((item) => (
              <div key={item.id} >   
                <Boxcomponent
                id={item.id}
                  img={item.img}
                  name={item.name}
                  content={item.content}
                />
              </div>
            ))}
          </div>
        </div>
          );
    }
    
