import React, { useState } from "react";
import { FaChevronDown, FaChevronUp, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Header(props) {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [Products, setProducts] = useState(false);
  const [Hardware, setHardware] = useState(false);
  const [Software, setSoftware] = useState(false);
  const [Researchhover, setResearchhover] = useState(false);
  const [Labshover, setLabshover] = useState(false);
  const [Publicationshover, setPublicationshover] = useState(false);
  const [researchClicked, setResearchClicked] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showCompanySubmenu, setShowCompanySubmenu] = useState(false);

  const toggleProducts = () => {
    setShowProducts(!showProducts);
  };

  const toggleCompanySubmenu = () => {
    setShowCompanySubmenu(!showCompanySubmenu);
  };
  const active=props.id;
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleNavigation = (route) => {
    setTimeout(() => {
      // Scroll to top after delay
      window.location.href = route; scrollToTop(); // Reload the page
    }, 1000); // Adjust the delay as needed for your animation
  };
  return (
    <div className="w-full md:h-[13%] h-[10%] bg-[#FDFDFD] flex items-center justify-center  fixed top-0 z-10 ">
      <div className="w-[90%] flex items-center justify-between pt-1">
        <div>
        
        <img className="w-[4rem] h-[3.666rem]  md:w-[5rem] md:h-[4.5rem] 2xl:w-[7.45rem] 2xl:h-[6rem]" src={require("../../src/Images/Headerlogo.png")} alt="Quantum Logo" />
        </div>
        <div className="sm:block hidden"> 
          <ul className="flex items-center justify-center text-white 2xl:gap-5 md:gap-3">
            <li
              onClick={() => {
                handleNavigation("/")
                // scrollToTop();
                // navigate("/")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal  hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 1
                  ? "border-b-2 text-[#3B84B4]   border-[#3B84B4]"
                  : "border-b-0"
              } xl:text-sm md:text-[0.75rem] 2xl:text-lg`}
            >
              Home
            </li>
           
            <li
              onMouseLeave={() => {
                setProducts(false);
              }}
              onMouseOver={() => {
                setProducts(true);
              }}
              className={`relative flex items-center justify-center gap-2 p-3 bg-[#FDFDFD] hover:text-[#6FC7E3] font-opensans font-normal cursor-pointer text-[#000000] ${
                active === 2
                  ? "border-b-2 text-[#3B84B4]  border-[#3B84B4]"
                  : "border-b-0"
              } xl:text-sm md:text-[0.75rem] 2xl:text-lg`}
            >
              Company
              <FaChevronDown className="mt-[0.10rem]" color="#3B84B4" />
              <div
                onMouseLeave={() => {
                  setProducts(false);
                }}
                className={`flex flex-col items-center justify-center shadow-md absolute top-[2.5rem]  left-0 w-[10rem] py-3 bg-[#FDFDFD] ${
                  Products ? "block" : "hidden"
                } xl:text-sm md:text-[0.75rem] 2xl:text-lg `}
              >
                <p
                  onClick={() => {
                    handleNavigation("/Aboutus")
                    // scrollToTop();
                    // navigate("/Aboutus")
                  }}
                  onMouseOver={() => {
                    setHardware(true);
                  }}
                  onMouseLeave={() => {
                    setHardware(false);
                  }}
                  className="flex items-center justify-center font-opensans font-normal gap-3 p-3 w-[8rem] text-[#000000] hover:text-[#6FC7E3] hover:bg-[#F3F3F3] hover:rounded-xl xl:text-sm md:text-[0.75rem] 2xl:text-lg"
                >
                  About us{" "}
                  
                </p>
                <p
                  onClick={() => {
                    handleNavigation("/Contactus")
                    // scrollToTop();
                    // navigate("/Contactus")
                  }}
                  onMouseOver={() => {
                    setSoftware(true);
                  }}
                  onMouseLeave={() => {
                    setSoftware(false);
                  }}
                  className="flex items-center justify-center font-opensans font-normal gap-3 p-3 w-[8rem] text-[#000000] hover:text-[#6FC7E3] hover:bg-[#F3F3F3] hover:rounded-xl xl:text-sm md:text-[0.75rem] 2xl:text-lg "
                >
                  Contact us{" "}
                  
                </p>
              </div>
            </li>
            <li
              onClick={() => {
                handleNavigation("/Products")
                // scrollToTop();
                // navigate("/Products")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal  hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 3
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]"
                  : "border-b-0"
              } xl:text-sm md:text-[0.75rem] 2xl:text-lg`}
            >
              Products
            </li>
            <li
              onClick={() => {
                handleNavigation("/Services")
                // scrollToTop();
                // navigate("/Services")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal  hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 4
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]"
                  : "border-b-0"
              } xl:text-sm md:text-[0.75rem] 2xl:text-lg`}
            >
              Services
            </li>
            <li
              onClick={() => {
                
                handleNavigation("/Partners")
                // scrollToTop();
                // navigate("/Partners")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 5
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]"
                  : "border-b-0"
              } xl:text-sm md:text-[0.75rem] 2xl:text-lg`}
            >
              Partners
            </li>
            <li
              onClick={() => {
                handleNavigation("/Ourip")
                // scrollToTop();
                // navigate("/Ourip")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 6
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]"
                  : "border-b-0"
              } xl:text-sm md:text-[0.75rem] 2xl:text-lg`}
            >
              Our IP's
            </li>
            <li
              onClick={() => {
                handleNavigation("/Resources")
                // scrollToTop();
                // navigate("/Resources")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal  hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 7
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]"
                  : "border-b-0"
              } xl:text-sm md:text-[0.75rem] 2xl:text-lg`}
            >
              {" "}
              Resources
            </li>
            
           
            <li
               onClick={() => {
                window.open("https://qulabs.ai/", "_blank");  
              }}
              className={`px-3 py-1 bg-[#2DD9F1] font-opensans font-normal hover:text-[#ffffff]/70 cursor-pointer  rounded-lg text-[#ffffff]  xl:text-sm md:text-[0.75rem] 2xl:text-lg`}
            >
              Qulabs
            </li>
            <li
               onClick={() => { 
                window.open("https://www.quacademy.com/", "_blank");
              }}
              className={`px-3 py-1 bg-[#2DD9F1] font-opensans font-normal hover:text-[#ffffff]/70 cursor-pointer  rounded-lg text-[#ffffff]  xl:text-sm md:text-[0.75rem] 2xl:text-lg`}
            >
              QuAcademy
            </li>
          </ul>
        </div>
        <div className="sm:hidden">
          <FaBars className="text-[#000000] text-2xl cursor-pointer"  onClick={() => setShowMenu(!showMenu)} />
        </div>

      </div>
      {showMenu && (
        <div className={`absolute top-full rounded-lg right-0 w-[50%] ${showMenu ? 'menu-slide' : ''} h-${researchClicked ? '45' : '40'}% bg-[#FDFDFD] text-white py-6`}>
           <ul className="flex  flex-col items-center justify-center text-white gap-3 ">
           <li
              onClick={() => {
                handleNavigation("/")
                // scrollToTop();
                // navigate("/")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal  hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 1
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4]  border-[#3B84B4]"
                  : "border-b-0"
              } text-xs `}
            >
              Home
            </li>
            <li
              onClick={toggleCompanySubmenu}
              className={`relative flex items-center justify-center gap-2 p-3 bg-[#FDFDFD] hover:text-[#6FC7E3] font-opensans font-normal cursor-pointer text-[#000000] ${
                active === 2 ? 'border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]' : 'border-b-0'
              } text-xs`}
            >
              Company
              {showCompanySubmenu ? <FaChevronUp className="mt-[0.10rem]" /> : <FaChevronDown className="mt-[0.10rem]" />}
            </li>
            {showCompanySubmenu && (
              <li className={`flex flex-col items-center justify-center shadow-md w-[10rem] bg-[#FDFDFD] rounded-lg text-xs`}>
                <p
                  onClick={() => {
                    handleNavigation('/Aboutus');
                    // scrollToTop();
                    // navigate('/Aboutus');
                  }}
                  className="flex items-center justify-center font-opensans font-normal gap-3 p-3 w-[8rem] text-[#000000] hover:text-[#6FC7E3] hover:bg-[#F3F3F3] hover:rounded-xl text-xs"
                >
                  About us
                </p>
                <p
                  onClick={() => {
                    handleNavigation('/Contactus');
                    // scrollToTop();
                    // navigate('/Contactus');
                  }}
                  className="flex items-center justify-center font-opensans font-normal gap-3 p-3 w-[8rem] text-[#000000] hover:text-[#6FC7E3] hover:bg-[#F3F3F3] hover:rounded-xl text-xs"
                >
                  Contact us
                </p>
              </li>
            )}
              <li
              onClick={() => {
                handleNavigation("/Products");
                // scrollToTop();
                // navigate("/Products")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal  hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 3
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]"
                  : "border-b-0"
              } text-xs `}
            >
              Products
            </li>
            <li
              onClick={() => {
                handleNavigation("/Services")
                // scrollToTop();
                // navigate("/Services")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal  hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 4
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]"
                  : "border-b-0"
              } text-xs`}
            >
              Services
            </li>
            <li
              onClick={() => {
                handleNavigation("/Partners")
                // scrollToTop();
                // navigate("/Partners")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 5
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]"
                  : "border-b-0"
              } text-xs`}
            >
              Partners
            </li>
            <li
              onClick={() => {
                handleNavigation("/Ourip")
                // scrollToTop();
                // navigate("/Ourip")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal  hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 6
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]"
                  : "border-b-0"
              } text-xs`}
            >
              Our IP's
            </li>
            <li
              onClick={() => {
                handleNavigation("/Resources")
                // scrollToTop();
                // navigate("/Resources")
              }}
              className={`px-3 py-1 bg-[#FDFDFD] font-opensans font-normal  hover:text-[#6FC7E3] cursor-pointer text-[#000000] ${
                active === 7
                  ? "border-b-2 text-[#3B84B4] hover:text-[#3B84B4] border-[#3B84B4]"
                  : "border-b-0"
              } text-xs`}
            >
              {" "}
              Resources
            </li>
            
            
            <li
               onClick={() => {
                window.open("https://qulabs.ai/", "_blank");
              }}
              className={`px-3 py-1 bg-[#2DD9F1] font-opensans font-normal hover:text-[#ffffff]/70 cursor-pointer   rounded-lg text-[#ffffff] text-xs`}
            >
              Qulabs
            </li>
            <li
               onClick={() => { 
                window.open("https://www.quacademy.com/", "_blank");
              }}
              className={`px-3 py-1 bg-[#2DD9F1] font-opensans font-normal hover:text-[#ffffff]/70 cursor-pointer  rounded-lg text-[#ffffff]  text-xs`}
            >
              QuAcademy
            </li>
          </ul>
         
        </div>
      )}
    </div>
  );
}
