import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Bannersame from '../Components/Bannersame'
import { mentorsprofile } from '../Data/Profiles'
import { whitepapersdata } from '../Data/Whitepapers'
import Profilecomponent from '../Components/Profilecomponent'
import Whitepapersverticalcontainer from '../Components/Whitepapersverticalcontainer'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


export default function Resources() {

  const [selectedProduct, setSelectedProduct] = useState(null);
  const resumeFormRef = useRef(null);


  const leftBoxRef = useRef(null);
  const rightBoxRef = useRef(null);
  const containerRef = useRef(null);


  useEffect(() => {
    const container = containerRef.current;
    const leftBox = leftBoxRef.current;
    const rightBox = rightBoxRef.current;

    const t1 = gsap.timeline({ paused: true });
    t1.fromTo(
      leftBox,
      { opacity: 0, x: '-50%' },
      { opacity: 1, x: '0%', duration: 1, ease: 'power2.out' }
    ).fromTo(
      rightBox,
      { opacity: 0, x: '50%' },
      { opacity: 1, x: '0%', duration: 1, ease: 'power2.out' },
      '-=1' // Synchronize the animation of the right box with the left box
    );

    function handleMouseEnter() {
      t1.play();
    }

    function handleTouchStart() {
      t1.play();
    }

    container.addEventListener('mouseenter', handleMouseEnter);
    container.addEventListener('touchstart', handleTouchStart);

    return () => {
      container.removeEventListener('mouseenter', handleMouseEnter);
      container.removeEventListener('touchstart', handleTouchStart);
    };



  }, []);



  const handleReadMoreClicked = (productId) => {
    // Find the selected product from whitepapersdata based on its ID
    const product = whitepapersdata.find((item) => item.id === productId);
    if (product) {
      setSelectedProduct(product.name);
      // Check if resumeFormRef is not null before calling scrollIntoView
      if (resumeFormRef.current) {
        resumeFormRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header id={7} />
      <div className="flex-1 bg-[#fdfdfd]">
        <Bannersame number={"6"} banner={"Resources.png"} head1={"Exploring"} head2={"Insights"} head3={"and"} head4={"Idea"} content={"At Quantum.ai, we value your input, inquiries, and feedback. Our mission is to foster collaboration and innovation, and your communication plays a vital role in achieving that. Whether you have questions about our cutting-edge quantum technologies, want to explore partnership opportunities, or simply want to get in touch, we're here to listen."} />
      </div>
      <div className='w-full bg-[#fdfdfd] flex flex-col items-center justify-center my-10 gap-6'>
        <div className='flex flex-col items-center justify-center gap-3'>
          <div className='font-opensansbold font-bold text-[#3b84b4] text-center text-2xl 2xl:text-3xl'>Discover Quantum AI's
            White Papers

          </div>
          <div className='sm:w-[70%] w-[80%] font-opensans font-medium text-sm 2xl:text-lg text-[#000000] text-justify'>At Quantum AI, we're dedicated to harnessing the potential of quantum computing and artificial intelligence across a range of categories. From finance and healthcare to manufacturing and logistics, we're pioneering solutions that transform industries and tackle complex challenges. Explore these diverse categories to see how quantum technology can drive efficiency and innovation. Join us in exploring the possibilities and unlocking the potential of quantum technology in various fields.

          </div>
        </div>
        <div className="w-full h-auto relative flex items-center justify-center px-5  ">
          <Whitepapersverticalcontainer onReadMoreClicked={handleReadMoreClicked} />
        </div>
      </div>
      {selectedProduct && (
        <div className='w-full flex items-center justify-center my-5' ref={resumeFormRef}>
          <div className="mt-5 sm:w-[80%] w-[90%] py-6 bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef]">
            <h1 className="w-full text-center font-black font-opensans text-[#3b84b4] xl:text-2xl 2xl:text-3xl my-3 ">{selectedProduct}</h1>
            <div className='w-full flex items-center justify-center'>
              <div className="w-[80%]  mb-2">
                {Object.entries(
                  whitepapersdata.find((values) => values.name === selectedProduct)
                ).map(([key, value]) => {
                  if (value !== "" && key !== "id" && key !== "name") {
                    return (
                      <div key={key}>
                        {typeof value === "object" ? (
                          <div>
                            {Object.entries(value).map(
                              ([nestedKey, nestedValue]) => (
                                <p className="py-1" key={nestedKey}>
                                  <span className="font-bold font-opensans text-[#3b84b4] text-lg xl:text-xl 2xl:text-2xl mr-2 ">
                                    {nestedKey} :
                                  </span>
                                  {"   "}
                                  {Array.isArray(nestedValue) ? (
                                    <ul className="list-disc pl-4 my-1">
                                      {nestedValue.map((item, index) => (
                                        <li
                                          key={index}
                                          className="font-normal text-justify font-opensans text-[#000000] text-xs 2xl:text-base "
                                        >
                                          {item}
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    <div className="font-normal text-justify font-opensans text-[#000000] text-xs 2xl:text-base ">
                                      {nestedValue}
                                    </div>
                                  )}
                                </p>
                              )
                            )}
                          </div>
                        ) : (
                          <p>{value}</p>
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        </div>
      )}


      <div ref={containerRef} className='relative w-[100%]  h-[18rem] xl:h-[34rem] 2xl:h-[38rem] aboutusGradient'>

        <img ref={leftBoxRef} className='absolute top-1/2 transform -translate-y-1/2 sm:left-5 left-0 w-[12.5rem] h-[10.14rem] lg:w-[37rem] lg:h-[30rem] object-fill' src={require(`../../src/Images/Resourcespageadd.png`)} alt="Banner"></img>
        <div ref={rightBoxRef} className='absolute sm:w-[50%] w-[55%] sm:right-20 right-4 top-1/2  transform -translate-y-1/2 flex flex-col justify-center items-center sm:gap-6 gap-2'>
          <div className='w-full text-right font-opensansbold font-light text-[0.65rem]  xl:text-sm 2xl:text-lg text-[#3b84b4]'>Unlocking Tomorrow's Potential Today
          </div>
          <div className='w-full flex flex-col sm:gap-0 gap-1'>
            <div className='w-full text-right text-[#000000] leading-tight font-opensansextrabold  font-extrabold text-[0.9rem] text-xl  xl:text-5xl 2xl:text-[4rem]'>Unlocking Quantum AI</div>
            <div className='w-full text-right wordGradient leading-tight font-opensansextrabold  font-extrabold text-xl  xl:text-5xl 2xl:text-[4rem]'>Success Stories</div>
          </div>
          <div className='w-[97%] sm:w-full   font-opensans font-semibold  text-justify sm:text-right text-[0.5rem] text-[#000000]/85 lg:text-sm 2xl:text-base sm:block hidden'>Explore Quantum AI Global's transformative case studies, where we showcase our pioneering solutions at the intersection of quantum computing and artificial intelligence. Dive into these real-world success stories and witness how we've partnered with organizations across diverse industries to conquer complex challenges and unlock new frontiers of efficiency, innovation, and growth. </div>
        </div>

      </div>



      <div className="flex flex-col items-center justify-center gap-8 md:px-20 md:py-10 px-5 py-7">
        <div className='flex flex-col items-center justify-center gap-3'>
          <div className='font-opensansbold font-bold text-[#3b84b4] text-2xl 2xl:text-3xl'>Mentors



          </div>
          <div className='sm:w-[60%] w-[80%] font-opensans font-medium text-sm 2xl:text-lg text-[#000000] text-justify'>Our mentors comprise industry leaders, entrepreneurs, and professionals who have excelled in their respective fields. They are passionate about sharing their insights and helping us and our stakeholders reach full potential. They foster a strong sense of community and work with us as trusted allies, offering ongoing support and building lifelong connections.

          </div>
        </div>
        <div className="flex flex-wrap  md:max-w-full 2xl:gap-5 md:gap-3 gap-1 mx-auto items-center justify-center md:px-20 md:py-10 px-3 py-7 cursor-pointer">
          {mentorsprofile.map((item) => (
            <div key={item.id} className="flex-item ">
              <Profilecomponent
                img={item.img}
                name={item.name}
                title={item.title}
                content={item.content}
                linkedin={item.linkedin}
              />
            </div>
          ))}
        </div>
      </div>
      <Footer showpage={false} />
    </div>
  )
}
