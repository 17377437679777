import React from 'react'

export default function Bannersame(props) {
  const numberInt = parseInt(props.number, 10);

  // Determine the position of the image based on odd or even number
  const isOddNumber = numberInt % 2 !== 0;
  const imagePosition = isOddNumber ? 'sm:left-5 2xl:left-10' : 'sm:right-5 2xl:right-10';
  const contentPosition = isOddNumber ? ' sm:right-12 2xl:right-14' : 'sm:left-12 2xl:left-14';
  const contentcenter=isOddNumber ? 'sm:text-right' : ' sm:text-left';
  return (
    <>
    <div className='w-[100%] sm:h-[100vh]  customGradient sm:block hidden'>

      <div className='relative w-[100%]  h-full flex sm:flex-row flex-col items-center justify-between px-3'>

        <img className={`absolute lg:w-[30rem] lg:h-[85%] 2xl:w-[50rem] 2xl:h-[95%] object-contain ${imagePosition} sm:top-1/2 transform -translate-y-1/2   sm:mt-10`} src={require(`../../src/Images/${props.banner}`)} alt="Banner"></img>

        <div className={`absolute top-[65%] sm:top-1/2 transform -translate-y-1/2  sm:w-[50%] w-[98%] ${contentPosition}  flex flex-col justify-center items-center gap-0 leading-tight`}>


          <div className={`font-opensansextrabold   font-extrabold w-[100%] ${contentcenter} tracking-wide text-[#000000]  lg:text-[2.7rem] 2xl:text-[3.5rem] `}><span className='wordGradient'>{props.head1} </span>{props.head2}</div>
          <div className={`font-opensansextrabold   font-extrabold w-[100%] ${contentcenter}  tracking-wide text-[#000000] lg:text-[2.7rem] 2xl:text-[3.5rem]`}><span className='wordGradient'>{props.head3}{" "}</span>{props.head4} </div>

          <div className={`font-opensans font-normal w-[100%] ${contentcenter} text-[#000000]/85 2xl:text-lg lg:text-sm `}>{props.content}</div>
        </div>
      </div>
    </div>
       <div className='w-[100%] h-[90vh]  customGradient sm:hidden block'>

       <div className='relative w-[100%]  h-full flex  flex-col items-center justify-between px-3'>
 
         <img className={`absolute w-[25rem] h-[50%] lg:w-[35rem] lg:h-[80%] object-contain  transform -translate-x-1/2 left-1/2  mt-32`} src={require(`../../src/Images/${props.banner}`)} alt="Banner"></img>
 
         <div className={`absolute top-[70%] left-1/2 transform -translate-x-1/2  sm:w-[50%] w-[98%] ${contentPosition}  flex flex-col justify-center items-center gap-0 leading-tight`}>
 
 
           <div className={`font-opensansextrabold   font-extrabold w-[100%] tracking-wide text-[#000000] text-center text-[1.8rem] lg:text-[2.7rem] 2xl:text-[3.5rem] `}><span className='wordGradient'>{props.head1} </span>{props.head2}</div>
           <div className={`font-opensansextrabold   font-extrabold w-[100%] tracking-wide text-[#000000] text-center text-[1.8rem] lg:text-[2.7rem] 2xl:text-[3.5rem]`}><span className='wordGradient'>{props.head3}{" "}</span>{props.head4} </div>
 
           <div className={`font-opensans font-normal w-[97%] text-center text-xs text-[#000000]/85 2xl:text-lg lg:text-sm `}>{props.content}</div>
         </div>
       </div>
     </div>
 
     </>



  )
}
