import React from 'react'
import Boxcomponent from './Bigboxcomponent';
import { productsdata } from '../Data/Products';
import Bigboxcomponent from './Bigboxcomponent';

export default function Productscontainer() {
    return (
        <div className="relative w-full">
      <div
        className="flex flex-wrap xl:max-w-[90%]  mx-auto items-center justify-center gap-4 md:px-10 md:py-10 px-3 cursor-pointer"
        
      >
        {productsdata.map((item) => (
          <div key={item.id} >   
            <Bigboxcomponent
            id={item.id}
              img={item.img}
              name={item.name}
              content={item.content}
            />
          </div>
        ))}
      </div>
    </div>
      );
}
