import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Bannersame from '../Components/Bannersame'

export default function News() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header id={8} />
      <div className="flex-1 bg-[#fdfdfd]">
        <Bannersame banner={"NewsBanner.png"} head1={""} head2={"Stay Informed,"} head3={"Stay Ahead"} head4={""} content={"At Quantum.ai, we value your input, inquiries, and feedback. Our mission is to foster collaboration and innovation, and your communication plays a vital role in achieving that. Whether you have questions about our cutting-edge quantum technologies, want to explore partnership opportunities, or simply want to get in touch, we're here to listen."} />
      </div>
      <div className="w-full h-auto flex flex-col items-center justify-center bg-[#fdfdfd] py-10 px-4 gap-12">
        <div className='w-full h-auto flex items-center justify-start px-10'>
          <div className="w-[60%] bg-[#fdfdfd] rounded-lg shadow-lg shadow-[#d5e5ef]">


            <div className='font-opensans font-semibold text-lg text-[#3b84b4] text-center'>Title</div>
            <p className="w-full px-6 flex items-center justify-center h-[6rem] overflow-hidden max-h-40 transition-max-height duration-300 ease-in-out hover:h-[20rem] font-opensans font-normal text-lg text-[#000000]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam hendrerit diam sed
              vehicula. In hac habitasse platea dictumst. Integer at odio id sapien vestibulum
              ultrices. Duis scelerisque pharetra ex, nec suscipit odio consectetur vel.
            </p>

          </div>
        </div>
        <div className='w-full h-auto flex items-center justify-end px-10 '>
          <div className="w-[60%] bg-[#fdfdfd] rounded-lg shadow-lg shadow-[#d5e5ef]">


            <div className='font-opensans font-semibold text-lg text-[#3b84b4] text-center'>Title</div>
            <p className="w-full px-6 flex items-center justify-center h-[6rem] overflow-hidden max-h-40 transition-max-height duration-300 ease-in-out hover:h-[20rem] font-opensans font-normal text-lg text-[#000000]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam hendrerit diam sed
              vehicula. In hac habitasse platea dictumst. Integer at odio id sapien vestibulum
              ultrices. Duis scelerisque pharetra ex, nec suscipit odio consectetur vel.
            </p>

          </div>
        </div>
      </div>
      <Footer showpage={false} />
    </div>
  )
}
