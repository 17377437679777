import React from 'react'
import Productsverticalcomponent from './Productsverticalcomponent';
import { mainproductsdata } from '../Data/Products';

export default function Productsverticalcontainer() {
    return (
        <div className="relative w-full">
      <div
        className="flex flex-wrap max-w-[100%] mx-auto items-center justify-center gap-10 py-7 px-3 cursor-pointer"
        
      >
        {mainproductsdata.map((item) => (
          <div key={item.id} >   
            <Productsverticalcomponent
            id={item.id}
            name={item.name}
              img={item.img}
              content={item.content}
            />
          </div>
        ))}
      </div>
    </div>
      );
}
