import React from 'react'

export default function Productshorizontalcomponent(props) {
    return (
        <div
            className=" flex flex-row items-center justify-center border-2 border-gray-100 md:px-6 px-2 md:py-3 py-2 md:rounded-2xl 2xl:rounded-3xl rounded-lg bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef]  md:w-[50rem] md:h-[22rem] 2xl:w-[60rem] 2xl:h-[28rem] w-[22rem] h-[40rem]"
        >
            <div className="flex sm:flex-row flex-col  items-center justify-center md:gap-8 gap-1">


                <img src={props.img} className='w-[9rem] h-[11rem] 2xl:w-[11rem] 2xl:h-[13rem]  object-fill'></img>

                <div className='flex flex-col items-center justify-center gap-8'>
                    <div className='flex flex-col items-center justify-center gap-4'>
                    <div className='w-[95%] xl:h-[4rem] 2xl:h-[5rem]  text-center sm:text-left wordGradient bottom-border-gradient font-black font-opensans text-lg xl:text-xl 2xl:text-2xl py-2'>{props.name}</div>


                    <div className='w-full px-[0.9rem] flex flex-wrap font-opensans font-normal text-[#000000] text-justify text-xs 2xl:text-base'>
                        {props.content}
                    </div>
                    </div>
                    <div className='w-full flex items-center justify-center sm:items-start'>
                        <div className='w-4/5  text-xs 2xl:text-base font-opensans font-normal bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] px-2 py-2 rounded-lg text-center'>
                            Patent Application No: <span className='text-[#3b84b4]'>{props.patent}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
