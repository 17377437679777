export const productsdata = [
    {
        id: 1,
        name: "Quantum Computing Hardware",
        img: require("../../src/Images/Quantum Computing Hardware.png"),
        content: "Our quantum computing hardware is designed for research, development, and commercial deployment. Our high-performance hardware delivers the speed and power you need to tackle complex problems and drive innovation. ",
    },
    {
        id: 2,
        name: "Quantum-AI Algorithms",
        img: require("../../src/Images/Quantum-AI Algorithms and Applications.png"),
        content: "Our Quantum/AI algorithms and applications are designed to help businesses tackle complex problems and improve efficiency. From optimization and simulation to cryptography and data analysis.",
    },
    {
        id: 3,
        name: "Quantum API and Tools",
        img: require("../../src/Images/Quantum API and Tools.png"),
        content: "Our API and tools make it easy for developers to integrate Quantum and AI technology into their existing applications and systems. Whether you’re looking to build new solutions from scratch or enhance existing applications.",
    },


];

export const mainproductsdata = [
    {
        id: 1,
        name: "Healmed",
        img: require("../../src/Images/Healmedproducts.png"),
        content: "HealMed is Our AI based Clinical Decision Support System (CDSS) that automates patient mental health assessments, stratifies their responses and helps in improving patient outcomes. It solves the problem of severe shortages of mental health providers and increasing case load per provider and reduce their burnout. We aim to cater multiple benefits for each user in the Mental Health Care Delivery Ecosystem.",
    },
    {
        id: 2,
        name: "LabAI",
        img: require("../../src/Images/Labaiproducts.png"),
        content: "Enables the customers to search and review policies, procedures and provide risk assessments to our clients, all different kind of compliances required to be adhered to like HIPAA guidelines. One step ahead of your competitors, our Al Global Compliance provides you with a comprehensive view of the regulatory and legal framework across various industries around the world. Our platform helps you find the right fit for your business by providing risk assessments, policies, and guidelines in different regulatory frameworks.",
    },
    {
        id: 3,
        name: "Claims",
        img: require("../../src/Images/Claimsproducts.png"),
        content: "Appeals patient-specific, Denials-specific, and payer-specific appeals letters using our Al. Our system sends appeal letters to the appropriate parties based on each individual’s circumstances, with a 99% accuracy rate based on our Al evaluation. The system also provides an easy way for patients to communicate their experience with an organization and receive reimbursement.",
    },
    {
        id: 4,
        name: "QSleeve",
        img: require("../../src/Images/Qsleeveproducts.png"),
        content: "The imminent threat of quantum computing to our communication security has prompted the development of an encryption wrapper based on quantum-resistant Post Quantum Cryptography algorithms, which will act as a protective barrier for data centers, ensuring data remains secure against potential breaches even as we transition to a quantum-secure system.",
    },
    {
        id: 5,
        name: "Qubitiq",
        img: require("../../src/Images/Qubitiqproducts.png"),
        content: "In the cutting-edge world of technology, quantum computing is revolutionizing data processing, and we propose an intelligent software system that combines machine intelligence and quantum computing to process diverse data types, using advanced machine learning techniques for classification and quantum data encoding to enable quantum algorithms in various applications.",
    },
    {
        id: 6,
        name: "QKD",
        img: require("../../src/Images/QKD.png"),
        content: "EPS-based QKD Demonstrator Box for Academia Kick start your experimentation in quantum communication with confidence using our EPS-based QKD Demonstrator Box, an essential tool for understanding quantum entanglement and encryption.",
    },
    {
        id: 7,
        name: "Quantum Memory",
        img: require("../../src/Images/QM.png"),
        content: "Room Temperature QM Demonstrator Box for Academia Witness the potential of Quantum Memory with our Room Temp QM Demonstrator Box, complete with an intuitive input preparation module for seamless experimentation.",
    },


]

export const productspopupdata = [
    {
        id: 6,
        name: "QKD",
        Content: {
            "EPS based QKD Demonstrator Box for Academia": "",
            "Description:": "EPS based QKD demonstrator is a unit for demonstration of two-point quantum key distribution using entanglement as a resource.",

            "Purpose:": [
                "Demonstration of entanglement pair generation with non-linear crystal through the SPDC process.",
                "Verification and test of quantum entangled nature of light.",
                "Perform experiments like coincidence counts, CHSH test, second-order correlation measurements, quantum state tomography, etc.",
                "Perform Quantum Key Distribution between two nodes with QBER calculation, quantum distributed key rate calculation, S/N ratio and many more with this demonstrator toolkit.",
            ],
            "Outcome:": [
                "Verify the entangled nature of down converted photons.",
                "Establish quantum keys between Alice and Bob.",
                "Security analysis of distributed keys.",
            ],
         
        },



    },
    {
        id: 7,
        name: "Quantum Memory",
        Content: {
            "Room Temp QM Demonstrator Box for Academia": "",
            "Description:": "Warm Rb vapor-based Room temperature Quantum memory demonstration tool Kit for Academia with Input preparation module",

            "Purpose:": [
                "Preparation of TTL pulsed laser input signal of 795 nm for storage with 6.8 GHz detuned phase synchronized control laser pulse.",
                "Preparation Atomic media as Warm Rb vapor setup inside the Gauss chamber to perform light-matter interaction.",
                "Demonstration of Electromagnetically Induced transparency phenomena and slowing of group velocity of light in the atomic vapor media.",
                "Demonstrate Quantum Storage phenomena with the pulse laser signal and control laser by changing pulse width and storage time.",
            ],
            "Outcome:": [
                "Verify the EIT phenomena by the application of the Control laser and probe laser.",
                "Verify the Quantum storage by the application of the Control laser and probe laser.",
                "Calculate the efficiency and storage time relation at different temperatures of the Gauss chamber.",
            ],
         
        },

    },


]



export const patentproductsdata = [
    {
        id: 1,
        name: "Quantum-Resistant Cryptographic System for Secure Communication",
        img: require("../../src/Images/Qsleeveproducts.png"),
        content: "With the advent of Quantum algorithms and processors the eminent threat to our communication security paradigm is looming over our heads. The term Y2Q, more conveniently called Years to Quantum or the Q-DAY is not a distant future to us. This patent drives the development of our product Q-Sleeve which is based on the Post Quantum Cryptography algorithms which are considered to be quantum resistant. This will work above the current internet system within the organization and keep the data encrypted facilitating the security for Post Quantum Era (PQE) as well. By enabling this mechanism on an end user communication system will ensure that the data will still remain encrypted even if the public cryptosystem gets broken.",
        patent: "202341046359",
    },
    {
        id: 2,
        name: "An Intelligent System for Quantum Data Processing and Algorithm Recommendation",
        img: require("../../src/Images/Qubitiqproducts.png"),
        content: "We propose an intelligent software system that harnesses the power of machine intelligence and quantum computing to tackle the challenges of multi-modal data processing. This patent drives the development of our product QubitIQ that will offer invaluable guidance by mapping each suggested data encoding template to the most suitable quantum algorithms, empowering researcher’s and developers to leverage quantum computing effectively in solving real-world challenges.",
        patent: "202341052490",
    },
    {
        id: 3,
        name: "Rubidium based Magnetometer for Bio-sensing Application",
        img: require("../../src/Images/QMag.png"),
        content: "The invention presents a Spin Exchange Relaxation-Free (SERF)-based Alkali Metal Magnetometer optimized for bio-sensing applications, notably in Magneto encephalography (MEG), Magneto cardiography (MCG), and Magnetic Resonance Imaging (MRI). The device utilizes a Vertical External Cavity Surface Emitting Laser (VCSEL) emitting dual coaxial beams, enabling precise manipulation of polarized light.",
        patent: "202441028401",
    }

]

export const ipdata = [
    {
        id: 1,
        name: "Quantum Sensors Technologies",
        img: require("../../src/Images/Quantum Sensors Technologies.png"),
        content: "Where quantum effects such as entanglement or superposition are exploited in the undertaking of high-resolution of physical parameters.",
    },
    {
        id: 2,
        name: "Quantum Timing And Atomic Clocks",
        img: require("../../src/Images/Quantum Timing And Atomic Clocks.png"),
        content: "Methods and devices for time keeping which use electron transition frequency or other atomic scale properties as a frequency standard for timekeeping.",
    },

]