import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Bannersame from '../Components/Bannersame'
import Productshorizontalcontainer from '../Components/Productshorizontalcontainer'
import Ipcontainer from '../Components/Ipcontainer'

export default function Ourip() {
  return (
    <div className="flex flex-col min-h-screen">
    <Header id={6} />
    <div className="flex-1 bg-[#fdfdfd]">
      <Bannersame number={"5"} banner={"Ourips.png"}  head1={"Innovative."} head2={"Exclusive."} head3={"Discover our "} head4={"patented products"} content={"At Quantum.ai, we value your input, inquiries, and feedback. Our mission is to foster collaboration and innovation, and your communication plays a vital role in achieving that. Whether you have questions about our cutting-edge quantum technologies, want to explore partnership opportunities, or simply want to get in touch, we're here to listen."}/>
    </div>
    <div className="w-full h-auto relative flex items-center justify-center px-5  ">
          <Productshorizontalcontainer/>
        </div>

        <div className='w-full bg-[#fdfdfd] flex flex-col items-center justify-center my-10 gap-6'>
        <div className='flex flex-col items-center justify-center gap-3'>
          <div className='sm:w-[70%] w-[80%] font-opensans font-medium text-sm 2xl:text-lg text-[#000000] text-justify'>At Quantum Al Global, we believe in the transformative power of quantum and Al technology, and we are dedicated to delivering innovative solutions that help businesses transform and thrive. Contact us today to learn more about our services and how we can help you harness the full potential of these exciting technologies.</div>
        </div>
        <div className="w-full h-auto relative flex items-center justify-center px-5  ">
          <Ipcontainer />
        </div>
      </div>
    <Footer showpage={false} />
    </div>
  )
}
