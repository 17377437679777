import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import "./../App.css"
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Banner from '../Components/Banner'
import { GrInstagram } from "react-icons/gr";
import { RxTwitterLogo } from "react-icons/rx";
import { FiLinkedin } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Partnerslogocontainer from '../Components/Partnerslogocontainer'
import emailjs from '@emailjs/browser';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function Home() {
  const navigate = useNavigate();
  const compRef = useRef(null);
  const imgRef = useRef(null);

  const form = useRef();
  const [subject, setSubject] = useState(null);
  const [body, setBody] = useState(null);
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const containRef = useRef(null);
  const leftBoxRef = useRef(null);
  const rightBoxRef = useRef(null);
  const containerRef = useRef(null);


  useEffect(() => {
    const container = containerRef.current;
    const leftBox = leftBoxRef.current;
    const rightBox = rightBoxRef.current;

    const t1 = gsap.timeline({ paused: true });
    t1.fromTo(
      leftBox,
      { opacity: 0, x: '-50%' },
      { opacity: 1, x: '0%', duration: 1, ease: 'power2.out' }
    ).fromTo(
      rightBox,
      { opacity: 0, x: '50%' },
      { opacity: 1, x: '0%', duration: 1, ease: 'power2.out' },
      '-=1' // Synchronize the animation of the right box with the left box
    );

    function handleMouseEnter() {
      t1.play();
    }
    function handleTouchStart() {
      t1.play();
    }

    container.addEventListener('mouseenter', handleMouseEnter);
    container.addEventListener('touchstart', handleTouchStart);

    return () => {
      container.removeEventListener('mouseenter', handleMouseEnter);
      container.removeEventListener('touchstart', handleTouchStart);
    };



  }, []);



  useEffect(() => {
    const contain = containRef.current;
    const left = leftRef.current;
    const right = rightRef.current;
    if (window.innerWidth > 640) {
      const t2 = gsap.timeline({ paused: true });
      t2.fromTo(
        left,
        { opacity: 0, x: '-50%' },
        { opacity: 1, x: '0%', duration: 1, ease: 'power2.out' }
      ).fromTo(
        right,
        { opacity: 0, x: '50%' },
        { opacity: 1, x: '0%', duration: 1, ease: 'power2.out' },
        '-=1' // Synchronize the animation of the right box with the left box
      );

      function handleMouseEnter() {
        t2.play();
      }



      contain.addEventListener('mouseenter', handleMouseEnter);



    }
  }, []);

  const openInstagramProfile = () => {
    window.open('https://www.instagram.com/quantumaiglobal/', '_blank');
  };

  const openTwitterProfile = () => {
    window.open('https://twitter.com/i/flow/login?redirect_after_login=%2Fquantumaiglobal', '_blank');
  };

  const openLinkedInProfile = () => {
    window.open('https://www.linkedin.com/company/quantum-ai-global-company/', '_blank');
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_l8j1ytl', 'template_gkb9zeq', form.current, {
        publicKey: 'rcWUEyKg3TYfCLt2-',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  };
  useLayoutEffect(() => {
    // Animation for company
    gsap.fromTo("#company", {
      opacity: 0,
      y: window.innerWidth <= 640 ? 10 : 30,
      stagger: 0.2,
      duration: 1,
    }, {
      opacity: 1,
      y: 0,
      stagger: 0.2,
      duration: 1,
    });

    // Animation for content
    gsap.fromTo("#content", {
      opacity: 0,
      x: window.innerWidth <= 640 ? 0 : -30,
      stagger: 0.2,
      duration: 1,
    }, {
      opacity: 1,
      x: 0,
      stagger: 0.2,
      duration: 1,
    });
  }, []);
  useEffect(() => {
    gsap.fromTo(imgRef.current, {
      opacity: 0,
      x: window.innerWidth <= 640 ? 0 : 100,

      stagger: 0.2,
      duration: 1, // Rotate from the left center for a different effect
    }, {
      opacity: 1,
      x: 0,
      stagger: 0.2,
      duration: 1, // Use a power2 ease out for a smooth effect
    });
  }, []);
  return (
    <div className="w-full flex flex-col items-center justify-center min-h-screen bg-[#fdfdfd]">
      <Header id={1} />


      <div className="w-full flex-1 bg-black">
        <Banner
          mainBanner={
            <div className="relative w-[100%]   h-full flex  items-center justify-center sm:justify-between px-3">


              <div ref={compRef} className='absolute sm:w-[50%] w-full  lg:left-44 2xl:left-48 sm:top-[55%] transform -translate-y-1/2  text-container  flex flex-col justify-center items-center gap-0'>
                <div id="company" className='font-opensansextrabold   font-extrabold w-[100%] sm:text-left text-center sm:tracking-wide text-[#000000] text-[1.8rem] lg:text-[3rem] 2xl:text-[4rem]  lg:h-[2.8rem] 2xl:h-[4rem]'>Welcome To </div>
                <div id="company" className='font-opensansextrabold   font-extrabold w-[100%] sm:text-left text-center  sm:tracking-wide wordGradient text-[1.8rem] lg:text-[3rem] 2xl:text-[4rem] '>Quantum AI Global</div>
                <div className='w-full' id="content">
                  <div className='font-opensans font-semibold w-[100%] text-sm sm:text-left text-center  text-[#000000]/85 lg:text-sm 2xl:text-xl '>Leading the way in</div>
                  <div className='font-opensans font-semibold w-[100%] text-sm sm:text-left text-center  text-[#000000]/85 lg:text-sm 2xl:text-xl '>Quantum & AI Innovations</div></div>

              </div>
              <img ref={imgRef} className='lg:w-[35.6625rem] lg:h-[90%] 2xl:w-[50rem] 2xl:h-[50rem] object-fill sm:right-5 2xl:right-7 absolute sm:top-[54.9%] top-[40%] sm:-translate-x-0 w-[25rem] h-[60%] transform sm:-translate-y-1/2 ' src={require("../../src/Images/Homepage.png")} alt="Banner"></img>

            </div>


          }
        />
      </div>
      <div className=' w-full h-[45vh]  flex items-center justify-center py-5 xl:py-10'>
        <div className=' flex md:flex-row flex-col sm:gap-20 gap-5 justify-center items-center '>
          <div className='w-[18rem] h-[8rem] xl:w-[30rem] xl:h-[12rem] 2xl:w-[35rem] 2xl:h-[14rem] border-2 border-gray-100 flex flex-col gap-1 items-center justify-center bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] sm:px-8 px-4 py-2 sm:py-1 rounded-xl '>
            <div className='w-full font-opensansbold font-extrabold text-[#3b84b4] text-left text-base xl:text-[1.875rem] 2xl:text-[2.5rem]'>Products</div>
            <div className='w-full flex flex-wrap font-opensans font-normal text-[0.6rem] sm:text-xs 2xl:text-base text-[#000000] text-justify  leading-tight'>
              All our algorithms are designed with a vision of future in quantum space and are scalable, able to solve problems that are too big or too difficult for current technologies.
            </div>
            <div className='w-full items-start '>
              <div onClick={() => {
                scrollToTop();
                navigate("/Products")
              }} className=' sm:w-1/4 w-[30%] flex items-center justify-start cursor-pointer bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] px-2 py-2 rounded-lg text-[0.6rem] sm:text-xs 2xl:text-base font-opensans'>
                Learn more

              </div>
            </div>

          </div>
          <div className='w-[18rem] h-[8rem] xl:w-[30rem] xl:h-[12rem] 2xl:w-[35rem] 2xl:h-[14rem]  border-2 border-gray-100 flex flex-col gap-1 items-center justify-center bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] sm:px-8 px-4 py-2 sm:py-1 rounded-xl  '>
            <div className='w-full font-opensansbold font-extrabold text-[#3b84b4] text-left text-base xl:text-[1.875rem] 2xl:text-[2.5rem]'>Services</div>
            <div className='w-full flex flex-wrap font-opensans font-normal text-[0.6rem] sm:text-xs 2xl:text-base text-[#000000] text-justify  leading-tight'>
              All our algorithms are designed with a vision of future in quantum space and are scalable, able to solve problems that are too big or too difficult for current technologies.
            </div>
            <div className='w-full items-start '>
              <div onClick={() => {
                scrollToTop();
                navigate("/Products")
              }} className=' sm:w-1/4 w-[30%] flex items-center justify-start cursor-pointer bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] px-2 py-2 rounded-lg text-[0.6rem] sm:text-xs 2xl:text-base font-opensans'>
                Learn more

              </div>
            </div>

          </div>
        </div>

      </div>
      <div ref={containerRef} className='relative w-[100%] h-[18rem] xl:h-[34rem] 2xl:h-[38rem] homepageGradient'>

        <img ref={leftBoxRef} className='absolute top-1/2 transform -translate-y-1/2  sm:left-28 w-[10.125rem] h-[9.83rem] xl:w-[30.375rem] xl:h-[29.5rem] object-fill' src={require(`../../src/Images/Homepageadd.png`)} alt="Banner"></img>
        <div ref={rightBoxRef} className='absolute w-[50%] sm:right-20 right-10 top-1/2 transform -translate-y-1/2 flex flex-col justify-center items-center sm:gap-6 gap-2'>
          <div className='w-full text-right font-opensansbold font-light text-[#3b84b4] text-[0.65rem] sm:text-sm 2xl:text-lg '>Unlocking Tomorrow's Potential Today</div>
          <div className='w-full flex flex-col sm:gap-4 gap-1'>
            <div className='w-full text-right text-[#000000] font-opensansextrabold  font-extrabold text-xl  xl:text-5xl 2xl:text-[4rem] leading-tight sm:leading-loose '>Explore the  Quantum Revolution at</div>
            <div className='w-full text-right wordGradient font-opensansextrabold  font-extrabold text-xl  xl:text-5xl 2xl:text-[4rem]'>Quantum AI Global </div>
          </div>
          <div className=' w-[98%] sm:w-full  font-opensans font-semibold  text-right  text-[#000000]/85 text-xs lg:text-sm 2xl:text-lg sm:block hidden'>Quantum AI Global is a technology first start-up working in creating systems that convert data into intelligence by building products that generate usable information. We see that only a fraction of enterprise data is converted into Intelligence today.</div>


        </div>



      </div>
      <Partnerslogocontainer />
      <div ref={containRef} className="xl:w-[80%]  mx-auto flex md:flex-row flex-col   gap-12 items-center justify-center  px-10 py-5 bg-[#fdfdfd] ">
        <div ref={leftRef} className=" px-10 py-10 bg-[#fdfdfd] border-2 border-gray-100  shadow-lg rounded-xl  shadow-[#d5e5ef] ">
          <h2 className="w-full text-3xl 2xl:text-4xl font-opensansextrabold font-extrabold mb-1 wordGradient text-center">
            Just say Hello
          </h2>
          <div className='font-opensans font-normal w-full text-[#3b84b4] text-sm 2xl:text-lg mb-1 text-center'>
            Let us know more about you
          </div>
          <form ref={form} onSubmit={sendEmail} >
            <div className="md:mb-4 mb-1 flex text-sm items-center justify-center gap-2">

              <input
                type="text"
                id="name"
                name="user_name"
                placeholder='Name'
                className="mt-1 md:p-2 w-full border font-opensans font-medium text-[#000000] border-[#3b84b4] rounded-md bg-[#fdfdfd]  "
                required
              />
              <input
                type="tel"
                id="phoneNumber"
                name="user_phoneNumber"
                placeholder='Phone number'

                className="mt-1 md:p-2 w-full border font-opensans font-medium text-[#000000] border-[#3b84b4] rounded-md bg-[#fdfdfd] "
                required
              />
            </div>



            <div className="md:mb-4 text-sm mb-1">

              <input
                type="email"
                id="email"
                name="user_email"
                placeholder='Email'
                onChange={(e) => setSubject(e.target.value)}
                className="mt-1 md:p-2 w-full border font-opensans font-medium text-[#000000] border-[#3b84b4] rounded-md bg-[#fdfdfd]  "
                required
              />
            </div>

            <div className="md:mb-4 text-sm mb-1">

              <textarea onChange={(e) => setBody(e.target.value)}
                id="message"
                name="user_message"
                placeholder='Message'

                rows="4"
                className="mt-1 md:p-2 w-full border font-opensans font-medium text-[#000000] border-[#3b84b4] rounded-md bg-[#fdfdfd]  "
                required
              ></textarea>
            </div>



            <button
              type="submit"
              className="w-full bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] text-[#000000] font-normal text-xs 2xl:text-base  md:px-4 md:py-2 px-2 py-2 rounded-md hover:bg-[#fdfdfd]/30"
            >
              Send a message
            </button>

          </form>
        </div>
        <div ref={rightRef} className="md:w-[30%] w-full rounded-[0.3rem] h-auto flex flex-col items-center justify-center  gap-3 md:gap-6 md:py-12 md:px-8 py-6 bg-[#fdfdfd]">
          <div className='sm:w-full w-[70%] items-center justify-center flex-col '>
            <div className='w-full flex flex-wrap text-3xl 2xl:text-4xl font-opensansbold font-extrabold text-[#000000]'>
              We are Happy
              to Assist,
            </div>
            <div className='w-full sm:items-start items-center justify-center'>
              <div>
                <div className='font-opensans font-normal text-[#3b84b4] text-sm 2xl:text-lg'>
                  Our Office
                </div>
                <div className='font-opensans font-normal text-[#000000] text-xs 2xl:text-base '>
                  Hi-Tech City
                  Hyderabad, India
                </div>
              </div>
              <div>
                <div className='font-opensans font-normal text-[#3b84b4] text-sm 2xl:text-lg'>
                  E mail
                </div>
                <div className='font-opensans font-normal text-[#000000] text-xs 2xl:text-base '>
                  info@thequantum.ai
                </div>
              </div>
              <div>
                <div className='font-opensans font-normal text-[#3b84b4] text-sm 2xl:text-lg'>
                  Office Phone
                </div>

                <div className='font-opensans font-normal text-[#000000] text-xs 2xl:text-base text-left '>
                  +1 (732) 227 4433
                </div>
                <div className='font-opensans font-normal text-[#000000] text-xs 2xl:text-base text-left'>+91 (812) 102 7006</div></div>

            </div>
          </div>
          <div className='sm:w-full  w-[70%] items-center justify-center flex-col'>
            <div className='w-[70%] flex flex-wrap text-3xl font-opensansbold font-extrabold text-[#000000]'>
              Feel free to
            </div>
            <div className='w-[70%] flex flex-wrap text-3xl 2xl:text-4xl font-opensansbold font-extrabold wordGradient'>
              contact us
            </div>
            <div className='w-full items-start justify-center'>
              <div>

                <div className='font-opensans font-normal text-[#000000] text-xs 2xl:text-base'>
                  Mon – Fri : 9.00am to 6.00pm
                </div>
                <div className='font-opensans font-normal text-[#000000] text-xs 2xl:text-base'>
                  Sat : 10.00am to 5.00pm
                </div>
                <div className='font-opensans font-normal text-[#000000] text-xs 2xl:text-base'>
                  Sunday: Holiday
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-3.5  md:mt-2 ">
              <div onClick={openInstagramProfile} className="flex items-center justify-center w-[2.5rem] h-[2.5rem] bg-[#fdfdfd]  hover:bg-[#EBF2F8]  shadow-lg shadow-[#d5e5ef] rounded-3xl cursor-pointer "><GrInstagram size={20} color="#3b84b4" /></div>


              <div onClick={openTwitterProfile} className=" flex items-center justify-center w-[2.5rem] h-[2.5rem] bg-[#fdfdfd]  hover:bg-[#EBF2F8] shadow-lg shadow-[#d5e5ef] rounded-3xl cursor-pointer "><RxTwitterLogo size={20} color="#3b84b4" /></div>

              <div onClick={openLinkedInProfile} className=" flex items-center justify-center w-[2.5rem] h-[2.5rem] bg-[#fdfdfd] hover:bg-[#EBF2F8] shadow-lg shadow-[#d5e5ef]  cursor-pointer  rounded-3xl"><FiLinkedin size={20} color="#3b84b4" /></div>

            </div>
          </div>
        </div>

      </div>

      <Footer showpage={true} />
    </div>
  )
}
