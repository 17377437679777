import React, { useState, useRef , useEffect,useLayoutEffect} from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Bannersame from '../Components/Bannersame'
import Aboutuscontainer from '../Components/Aboutuscontainer'
import Simpleboxcomponent from '../Components/Simpleboxcomponent'
import { aboutussimpledata } from '../Data/Aboutus'
import { directorsprofile, coreteamprofile, advisoryprofile } from '../Data/Profiles'
import Profilecomponent from '../Components/Profilecomponent'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


export default function Aboutus() {

  const leftBoxRef = useRef(null);
  const rightBoxRef = useRef(null);
  const containerRef = useRef(null);
 

  useEffect(() => {
    const container = containerRef.current;
    const leftBox = leftBoxRef.current;
    const rightBox = rightBoxRef.current;

    const t1 = gsap.timeline({ paused: true });
  t1.fromTo(
    leftBox,
    { opacity: 0, x: '-50%' },
    { opacity: 1, x: '0%', duration: 1, ease: 'power2.out' }
  ).fromTo(
    rightBox,
    { opacity: 0, x: '50%' },
    { opacity: 1, x: '0%', duration: 1, ease: 'power2.out' },
    '-=1' // Synchronize the animation of the right box with the left box
  );

  function handleMouseEnter() {
    t1.play();
  }

  function handleTouchStart() {
    t1.play();
  }

  container.addEventListener('mouseenter', handleMouseEnter);
  container.addEventListener('touchstart', handleTouchStart);

  return () => {
    container.removeEventListener('mouseenter', handleMouseEnter);
    container.removeEventListener('touchstart', handleTouchStart);
  };
 


}, []);
const partnersRef = useRef(null);
const partnerImagesRefs = useRef([]);
useEffect(() => {
  gsap.fromTo(partnerImagesRefs.current, {
    opacity: 0,
    y: 50,
  }, {
    opacity: 1,
    y: 0,
    duration: 1,
    stagger: 0.2,
    ease: "power2.out",
    scrollTrigger: {
      trigger: partnersRef.current,
      start: "top bottom-=200",
      toggleActions: "play none none none"
    }
  });
}, []);


  return (
    <div className="flex flex-col min-h-screen">
      <Header id={2} />
      <div className="bg-[#fdfdfd]">
        <Bannersame number={"1"} banner={"Contactus.png"} head1={"Crafting"} head2={"Tomorrow's"} head3={"Innovations"} head4={"Today"} content={"At Quantum AI Global, we lead the quantum computing revolution, fusing quantum technology with AI innovation. Our mission is to redefine industries by solving complex challenges, and our experts are at the forefront of this transformation. Join us as we shape the future of computing and artificial intelligence."} />
      </div>
      <div className='w-full bg-[#fdfdfd] flex flex-col items-center justify-center my-10 gap-6'>
        <div className='flex flex-col items-center justify-center gap-3'>
          <div className='font-opensansbold font-bold text-[#3b84b4] text-2xl 2xl:text-3xl'>Our Journey

          </div>
          <div className='sm:w-[60%] w-[80%] font-opensans font-medium text-sm 2xl:text-lg text-[#000000] text-justify'>Founded by a team of experts in Quantum computing, Al and related fields, Quantum Al Global is committed to delivering innovative solutions that meet the unique needs of our clients. Our team combines deep expertise in quantum and AI technologies with a passion for solving complex problems and delivering results. Our team of experts combines deep expertise in quantum computing and artificial intelligence to deliver customized solutions for our clients.</div>
        </div>
        <div className="w-full h-auto relative flex flex-col items-center justify-center px-5  ">
          <div className='font-opensansbold font-bold text-[#3b84b4]  text-xl xl:text-3xl 2xl:text-4xl'>Our portfolio of products includes

          </div>
          <Aboutuscontainer />
        </div>
      </div>
      <div ref={containerRef} className='relative w-[100%]  h-[18rem] xl:h-[34rem] 2xl:h-[38rem] aboutusGradient'>

        <img ref={leftBoxRef} className='absolute top-1/2 transform -translate-y-1/2 sm:left-5 left-0 w-[12.33rem] h-[9.72rem] lg:w-[37rem] lg:h-[30rem]  object-fill' src={require(`../../src/Images/set1.png`)} alt="Banner"></img>
        <div ref={rightBoxRef} className='absolute w-[50%]  sm:right-20 right-4 top-1/2 transform -translate-y-1/2 flex flex-col justify-center items-center sm:gap-6 gap-2'>
          <div className='w-full text-right font-opensansbold font-light text-[#3b84b4] text-[0.65rem] xl:text-sm 2xl:text-lg '>Discover Our Quantum Journey
          </div>
          <div className='w-full flex flex-col sm:gap-0'>
            <div className='w-full text-right text-[#000000] font-opensansextrabold  font-extrabold text-xl  xl:text-5xl 2xl:text-[4rem] leading-tight sm:leading-loose '>Pioneering Innovation  at the intersection of</div>

            <div className='w-full text-right wordGradient font-opensansextrabold  font-extrabold text-xl lg:text-lg  xl:text-5xl 2xl:text-[4rem]'>Quantum Computing

              and AI</div>
          </div>
          <div className='  w-[98%] sm:w-full  font-opensans font-semibold  sm:text-right text-justify  text-[#000000]/85  lg:text-sm 2xl:text-lg sm:block hidden'>Whether you're new to quantum and AI tech or want to elevate your current operations, we're here to assist. Our experts offer tailored solutions, training, and support to maximize the benefits of these cutting-edge technologies. We lead in quantum and AI R&D, dedicated to delivering innovative and practical products and services. Our mission is to empower businesses to leverage these technologies for growith and efficiency of the curve.</div>

        </div>

       


      </div>
      <div ref={partnersRef} className="flex flex-col items-center justify-center md:py-10  py-7">
        <div className="flex flex-wrap max-w-full md:gap-5 gap-4 items-center justify-center  px-3 py-7 cursor-pointer">
          {aboutussimpledata.map((item) => (
            <div ref={el => partnerImagesRefs.current[item.id] = el} key={item.id} className="flex-item opacity-0">
              <Simpleboxcomponent
                name={item.name}
                content={item.content}

              />
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="flex flex-col items-center justify-center gap-8 md:px-20 md:py-10 px-5 py-7">
          <div className='flex flex-col items-center justify-center gap-3'>
            <div className='font-opensansbold font-bold text-[#3b84b4] text-2xl xl:text-3xl 2xl:text-4xl'>Meet Our Team



            </div>
            <div className='sm:w-[60%] w-[80%] font-opensans font-medium text-sm 2xl:text-lg text-[#000000] text-justify'>At Quantum AI Global, our vision is to usher in a new era of quantum-powered intelligence, where the potential of quantum computing meets the limitless possibilities of artificial intelligence. We aspire to reshape industries, solve complex challenges, and drive innovation, creating a future where quantum and AI technologies empower individuals and organizations to achieve extraordinary feats.</div>
          </div>
          <div className='flex flex-col items-center justify-center gap-2'>
            <p className="font-opensansbold font-bold text-xl md:text-2xl 2xl:text-3xl text-[#3b84b4]">
              Board Of Directors
            </p>
            <div className="flex flex-wrap max-w-full 2xl:gap-5 md:gap-3 gap-2 mx-auto items-center justify-center md:px-20 md:pb-10 px-3 pb-2 cursor-pointer">
              {directorsprofile.map((item) => (
                <div key={item.id} className="flex-item ">
                  <Profilecomponent
                    img={item.img}
                    name={item.name}
                    title={item.title}
                    content={item.content}
                    linkedin={item.linkedin}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className='flex flex-col items-center justify-center gap-3'>
            <p className="font-opensansbold font-bold text-xl md:text-2xl 2xl:text-3xl  text-[#3b84b4]">
              Core Team
            </p>
            <div className="flex flex-wrap max-w-full  md:gap-6 gap-2 mx-auto items-center justify-center   px-3 pb-10 cursor-pointer">
              {coreteamprofile.map((item) => (
                <div key={item.id} className="flex-item ">
                  <Profilecomponent
                    img={item.img}
                    name={item.name}
                    title={item.title}
                    content={item.content}
                    linkedin={item.linkedin}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className='flex flex-col items-center justify-center gap-2'>
            <p className="font-opensansbold font-bold text-xl md:text-2xl 2xl:text-3xl text-[#3b84b4]">
              Advisory
            </p>
            <div className="flex flex-wrap max-w-full 2xl:gap-5 md:gap-3 gap-2 mx-auto items-center justify-center md:px-5 md:pb-10 px-3 pb-7 cursor-pointer">
              {advisoryprofile.map((item) => (
                <div key={item.id} className="flex-item ">
                  <Profilecomponent
                    img={item.img}
                    name={item.name}
                    title={item.title}
                    content={item.content}
                    linkedin={item.linkedin}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer showpage={true} />
    </div>
  )
}
