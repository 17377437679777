import React from 'react'
import { whitepapers } from '../Data/Whitepapers';
import Whitepaperscomponent from './Whitepaperscomponent';

export default function Whitepapersverticalcontainer({ onReadMoreClicked }) {
  return (
    <div className="relative w-full">
  <div
    className="flex flex-wrap max-w-[100%] mx-auto items-center justify-center gap-10 py-10 px-3 "
    
  >
    {whitepapers.map((item) => (
      <div key={item.id} >   
        <Whitepaperscomponent
        id={item.id}
          img={item.img}
          name={item.name}
          content={item.content}
          onReadMoreClicked={onReadMoreClicked}
        />
      </div>
    ))}
  </div>
</div>
  );
}
