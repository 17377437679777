export const aboutusdata=[
    {
        id:1,
        name:"Quantum Computing Hardware",
        img:require("../../src/Images/Quantum Computing Hardwares.png"),
        content:"After achieving several breakthroughs in quantum architecture and silicon photonics, we're now on the cusp of creating a practical, large-scale, error-corrected quantum computer, setting us apart in the field.",
    },
    {
        id:2,
        name:"AI Software Platforms",
        img:require("../../src/Images/AI Software Platformss.png"),
        content:"Our enterprise software allows customers to build solutions for their hardest problems with the most advanced software available today.",
    },
    {
        id:3,
        name:"Quantum-AI Services",
        img:require("../../src/Images/Quantum-AI Servicess.png"),
        content:"We’re helping forward-thinking enterprises build quantum-enhanced Al solutions to the most computationally complex business problems in their industry – while preparing our customers for the quantum future.",
    },
    
   
];

export const aboutussimpledata=[
    {
        id:1,
        name:"Vision",
        content:"At Quantum AI Global, our vision is to usher in a new era of quantum-powered intelligence, where the potential of quantum computing meets the limitless possibilities of artificial intelligence. We aspire to reshape industries, solve complex challenges, and drive innovation, creating a future where quantum and AI technologies empower individuals.",
    },
    {
        id:2,
        name:"Mission",
        content:"Our mission at Quantum AI Global is to lead the way in the convergence of quantum computing and artificial intelligence. We do this by relentlessly pursuing groundbreaking research, developing cutting-edge solutions, and providing unparalleled expertise to our clients. Our aim is to enable businesses and individuals to harness the full potential of these transformative technologies.",
    },
    {
        id:3,
        name:"Values",
        content:"At Quantum AI Global, our core values are innovation, integrity, collaboration, diversity, sustainability, customer-centricity, continuous learning, and global impact. These principles drive our mission to lead at the intersection of quantum computing and artificial intelligence, shaping a future where transformative technologies empower progress and innovation.",
    },
    
   
];