import React, { useState } from "react";
import { SlSocialLinkedin } from "react-icons/sl";

export default function Profilecomponent(props) {
    const [isHovered, setIsHovered] = useState(false);
    const openLink = (url, event) => {
      event.stopPropagation();
      window.open(url, "_blank");
    };
    return (
      <div className="bg-[#fdfdfd] flex flex-col items-center justify-center 2xl:gap-6 xl:gap-4 gap-3 2xl:px-6 2xl:py-8 xl:px-4 xl:py-6 px-2 py-4  md:rounded-2xl   2xl:w-[20rem] rounded-md 2xl:h-[22rem] xl:w-[16rem] xl:h-[20rem] w-[13rem] h-[16rem] "
      >
        <div className="flex items-center justify-center  w-[9rem] h-[9rem] rounded-[4.5rem]  border-2 border-gray-100   bg-[#fdfdfd] shadow-lg shadow-gray-400">
        <img
          className=" object-fill w-[8rem] h-[8rem]   rounded-full"
          src={props.img}
        ></img>
        </div>
        <div className="flex flex-col items-center justify-center md:gap-6 gap-1">
        
            <div className=" flex flex-col items-start justify-center md:gap-1 gap-[0.15rem]">
              <p className="w-full font-opensans font-semibold  md:text-sm 2xl:text-lg text-[#000000] text-[0.8rem] text-center">
                {props.name}
              </p>
              <p
                className={`w-full font-opensans font-semibold text-[0.5rem] xl:text-xs 2xl:text-base   text-[#000000]/70 text-center`}
              >
                {props.title}
              </p>
            </div>
            <div
              onClick={(event) => openLink(props.linkedin, event)}
              className="2xl:w-[4rem] 2xl:h-[4rem]  rounded-full  w-[2.5rem] h-[2.5rem]  bg-[#fdfdfd] flex items-center justify-center shadow-lg shadow-gray-400 "
            >
              <SlSocialLinkedin size={20} color="#3b84b4" className="hidden md:block" />
              <SlSocialLinkedin size={12} color="#3b84b4" className="block md:hidden" />
            </div>
          </div>
        </div>
    );
}
