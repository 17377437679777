// import React from 'react'

// export default function Productsverticalcomponent(props) {
//   return (
//     <div
//       className="flex flex-col items-center justify-center border-2 border-gray-100 md:px-3 md:py-3  rounded-2xl  bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] w-[22rem] h-[26rem]  xl:w-[30rem] xl:h-[28rem] 2xl:w-[32rem] 2xl:h-[35rem] "
//     >
//       <div className="flex flex-col items-center justify-center md:gap-10 gap-1">


//         <img src={props.img} className='w-[9rem] h-[11rem] 2xl:w-[11rem] 2xl:h-[13rem] object-fill'></img>
//         <div className='w-full px-[0.9rem] flex flex-wrap font-opensans font-normal text-[#000000] text-justify text-xs 2xl:text-base'>
//           {props.content}
//         </div>

//       </div>
//     </div>
//   )
// }
import React, { useState } from 'react';
import { RxCrossCircled } from "react-icons/rx";
import { productspopupdata } from '../Data/Products';

export default function ProductsVerticalComponent(props) {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  const togglePopup = (productName) => {
    setSelectedProduct(productName);
    setShowPopup(!showPopup);
  
  };
  const isProductInData = productspopupdata.some(product => product.name === props.name);



  return (
    <div className="flex flex-col items-center justify-center border-2 border-gray-100 md:px-3 md:py-3 rounded-2xl bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] w-[22rem] h-[26rem] xl:w-[30rem] xl:h-[28rem] 2xl:w-[32rem] 2xl:h-[35rem] ">
      <div className="flex flex-col items-center justify-center md:gap-10 gap-1">
        <img src={props.img} className="w-[9rem] h-[11rem] 2xl:w-[11rem] 2xl:h-[13rem] object-fill" alt="Product Image" />
        <div className="w-full px-[0.9rem] flex flex-wrap font-opensans font-normal text-[#000000] text-justify text-xs 2xl:text-base">
          {props.content}
        </div>
        {isProductInData && (
          <button onClick={() => togglePopup(props.name)} className="bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] rounded-md text-black font-normal text-xs 2xl:text-base py-2 px-4  mt-2">
            Know More
          </button>
        )}
        {showPopup && selectedProduct && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#fdfdfd] bg-opacity-50 backdrop-filter backdrop-blur-sm z-10  shadow-2xl ">
            <div className="w-[45rem] h-[35rem] flex flex-col items-center justify-start gap-8 py-6 px-11 rounded-[2rem] bg-[#fdfdfd] border-2 border-gray-100 shadow-lg shadow-[#d5e5ef]">
              <div className="w-full flex items-center justify-end mt-4 cursor-pointer">
                <RxCrossCircled
                  size={30}
                  color="#3b84b4"
                  onClick={() => togglePopup('')}
                />
              </div>
              <h1 className="w-full text-center font-black font-opensans text-[#3b84b4] xl:text-2xl 2xl:text-3xl my-3 ">{selectedProduct}</h1>
              <div className='w-full overflow-x-auto scrollbar-hide mb-2'>
                <div className="w-[90%]  mb-2">
                  {Object.entries(
                    productspopupdata.find((values) => values.name === selectedProduct)
                  ).map(([key, value]) => {
                    if (value !== "" && key !== "id" && key !== "name") {
                      return (
                        <div key={key}>
                          {typeof value === "object" ? (
                            <div>
                              {Object.entries(value).map(
                                ([nestedKey, nestedValue]) => (
                                  <p className="py-1" key={nestedKey}>
                                    <span className="font-bold font-opensans text-[#3b84b4] text-lg xl:text-xl 2xl:text-2xl mr-2 ">
                                      {nestedKey} :
                                    </span>
                                    {"   "}
                                    {Array.isArray(nestedValue) ? (
                                      <ul className="list-disc pl-4 my-1">
                                        {nestedValue.map((item, index) => (
                                          <li
                                            key={index}
                                            className="font-normal text-justify font-opensans text-[#000000] text-xs 2xl:text-base "
                                          >
                                            {item}
                                          </li>
                                        ))}
                                      </ul>
                                    ) : (
                                      <div className="font-normal text-justify font-opensans text-[#000000] text-xs 2xl:text-base ">
                                        {nestedValue}
                                      </div>
                                    )}
                                  </p>
                                )
                              )}
                            </div>
                          ) : (
                            <p>{value}</p>
                          )}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
