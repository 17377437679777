import React from 'react'
import { BrowserRouter, Route,Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Aboutus from './Pages/Aboutus';
import Contactus from './Pages/Contactus';
import Products from './Pages/Products';
import Partners from './Pages/Partners';
import Ourip from './Pages/Ourip';
import Resources from './Pages/Resources';
import News from './Pages/News';
import Services from './Pages/Services'


export default function App() {
  return <BrowserRouter>
  <Routes>
    <Route path="/" Component={Home}/>
    <Route path="/Aboutus" Component={Aboutus}/>
    <Route path="/Contactus" Component={Contactus}/>
    <Route path="/Products" Component={Products}/>
    <Route path="/Services" Component={Services}/>
    <Route path="/Partners" Component={Partners}/>
    <Route path="/Ourip" Component={Ourip}/>
    <Route path="/Resources" Component={Resources}/>
    <Route path="/News" Component={News}/>
   
    
    

  </Routes>
  </BrowserRouter>
}


