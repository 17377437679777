import React from 'react'
import "./../App.css"

export default function Banner(props) {
  return (
    <div className='w-full sm:h-[95vh] h-[90vh] customGradient'>
        {props.mainBanner}
    </div>
  )
}
