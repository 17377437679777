import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Bannersame from '../Components/Bannersame'
import Partnerslogocontainer from '../Components/Partnerslogocontainer'
import Simpleboxcomponent from '../Components/Simpleboxcomponent'
import { partnerssimpledata } from '../Data/Partners'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default function Partners() {
  const partnersRef = useRef(null);
  const partnerImagesRefs = useRef([]);
  useEffect(() => {
    gsap.fromTo(partnerImagesRefs.current, {
      opacity: 0,
      y: 50,
    }, {
      opacity: 1,
      y: 0,
      duration: 1,
      stagger: 0.2,
      ease: "power2.out",
      scrollTrigger: {
        trigger: partnersRef.current,
        start: "top bottom-=200",
        toggleActions: "play none none none"
      }
    });
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header id={5} />
      <div className="flex-1 bg-[#fdfdfd]">
        <Bannersame number={"4"} banner={"Partners.png"} head1={"Elevating Possibilities Together"} head2={"Our Trusted Partnerships"} head3={""} head4={""} content={"At Quantum.ai, we value your input, inquiries, and feedback. Our mission is to foster collaboration and innovation, and your communication plays a vital role in achieving that. Whether you have questions about our cutting-edge quantum technologies, want to explore partnership opportunities, or simply want to get in touch, we're here to listen."} />
      </div>
      <Partnerslogocontainer />
      <div className="flex flex-col items-center justify-center md:py-10 gap-2 py-7">
        <div className='flex flex-col items-center justify-center gap-2 '>
          <div className='font-opensansbold font-normal text-[#000000] xl:text-[1rem] 2xl:text-[1.2rem]'>Testimonials</div>

          <div className='flex flex-col items-center justify-center gap-0 leading-tight'>
            <div className='flex flex-col items-center justify-center'>
              <div className='font-opensansextrabold font-black text-[#000000] xl:text-[2rem] 2xl:text-[2.5rem]'>Check what our</div>
              <div className='font-opensansextrabold font-black text-[#000000] xl:text-[2rem] 2xl:text-[2.5rem]'>
                <span className='wordGradient'>Clients say</span> about us
              </div>
            </div>
          </div>
        </div>
        <div ref={partnersRef} className=' sm:w-[30%] w-[70%] font-opensans font-medium text-[#000000] text-sm xl:text-sm 2xl:text-lg text-center'>Our clients' testimonials showcase the real-world impact of our products and services.</div>
        <div className="flex flex-wrap max-w-full md:gap-5 gap-1 mx-auto items-center justify-center py-10 px-3  cursor-pointer">
          {partnerssimpledata.map((item) => (
            <div ref={el => partnerImagesRefs.current[item.id] = el} key={item.id} className="flex-item opacity-0">
              <Simpleboxcomponent
                name={item.name}
                content={item.content}

              />
            </div>
          ))}
        </div>
      </div>
      <Footer showpage={false} />
    </div>
  )
}
