import React from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { TbMail } from "react-icons/tb";
import { IoCallOutline } from "react-icons/io5";
import { GrInstagram } from "react-icons/gr";
import { LuFacebook } from "react-icons/lu";
import { RxTwitterLogo } from "react-icons/rx";
import { FiLinkedin } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import logoSVG from '../../src/Images/Headerlogo.png';

export default function Footer(props) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const navigate = useNavigate();
  const handleEmailClick = () => {
    window.location.href = 'mailto:info@qulabs.ai';
  };

  const handlePhoneClick = () => {
    window.location.href = 'tel:+916281684342';
  };

  const openInstagramProfile = () => {
    window.open('https://www.instagram.com/qulabssoftware/', '_blank');
  };

  const openTwitterProfile = () => {
    window.open('https://twitter.com/i/flow/login?redirect_after_login=%2Fqulabssoftware', '_blank');
  };

  const openLinkedInProfile = () => {
    window.open('https://www.linkedin.com/company/qulabs-software-india/', '_blank');
  };
  return (
    <div className={`flex flex-col  items-center justify-center w-full ${props.showpage ? 'md:h-[27rem] h-auto pt-10 pb-1' : 'md:h-[13.5rem] h-auto'} bg-[#FDFDFD] gap-4 mt-5 `}>
      {
        props.showpage && (
          <div className="flex flex-col  gap-4 w-[75%] border-2 border-[#3b84b4] px-[2.25rem] py-[1.25rem] rounded-3xl">
            <div className="text-center text-[#3b84b4] font-opensans font-semibold 2xl:text-2xl md:text-xl text-md">
              Where can you find us?
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center xl:gap-12 gap-6">
              <a href="https://maps.app.goo.gl/hd4Mw2vCMX5STjXz5"
                target="_blank" rel="noreferrer" className="flex items-center justify-center gap-5 xl:w-[24.3rem] xl:h-[5.37rem] md:w-[22rem] md:h-[5rem]  w-[16rem] h-[4rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] hover:bg-[#EBF2F8] rounded-2xl px-[1.875rem] py-[0.625rem]">
                <HiOutlineLocationMarker size={24} color="#3b84b4" />
                <div className="w-[90%] flex flex-col items-center justify-center">
                  <div className=" sm:w-[18rem] text-center w-[90%] text-[#000000] font-opensans   text-xs lg:text-sm text-[0.6rem]  2xl:text-base font-medium ">
                    22 Meridian Rd Unit 14



                  </div>
                  <div className=" sm:w-[18rem] text-center w-[90%]  text-[#000000] font-opensans   text-xs lg:text-sm text-[0.6rem]  2xl:text-base  font-medium ">
                    Edison, NJ 08820



                  </div>
                </div>


              </a>
              <a href="https://maps.app.goo.gl/Y7e6sdXc3U5DjCiD9"
                target="_blank" rel="noreferrer" className="flex items-center justify-center gap-5 xl:w-[24.3rem] xl:h-[5.37rem] md:w-[22rem] md:h-[5rem]  w-[16rem] h-[4rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] hover:bg-[#EBF2F8] rounded-2xl px-[1.875rem] py-[0.625rem]">
                <HiOutlineLocationMarker size={24} color="#3b84b4" />
                <div className="w-[90%] flex flex-wrap sm:w-[18rem]  text-center text-[#000000] font-opensans text-xs lg:text-sm text-[0.6rem]  2xl:text-base font-medium">
                  Suite 305B, Capital Park,

                  HITEC City Hyderabad, Telangana 500081
                </div>
              </a>

            </div>
            <div className="flex flex-col md:flex-row items-center justify-center xl:gap-12 gap-6">
              <a href="https://www.google.com/maps/search/USA+Qulabz+Inc+16192+Coastal+Highway+Lewes+Delaware,+19958/@38.782478,-75.2334244,12z/data=!3m1!4b1?entry=ttu"
                target="_blank" rel="noreferrer" className="flex items-center justify-center gap-5 xl:w-[24.3rem] xl:h-[5.37rem] md:w-[22rem] md:h-[5rem]  w-[16rem] h-[4rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] hover:bg-[#EBF2F8] rounded-2xl px-[1.875rem] py-[0.625rem]">
                <HiOutlineLocationMarker size={24} color="#3b84b4" />
                <div className="w-[90%] flex flex-col items-center justify-center">
                  <div className=" sm:w-[18rem] text-center w-[90%] text-[#000000] font-opensans   text-xs lg:text-sm text-[0.6rem]  2xl:text-base font-medium ">
                    FDRK2335, Compass Building


                  </div>
                  <div className=" sm:w-[18rem] text-center w-[90%] text-[#000000] font-opensans   text-xs lg:text-sm text-[0.6rem]  2xl:text-base  font-medium ">
                    Ras Al Khaimah, UAE


                  </div>
                </div>


              </a>
              <a href="https://www.google.com/maps/search/USA+Qulabz+Inc+16192+Coastal+Highway+Lewes+Delaware,+19958/@38.782478,-75.2334244,12z/data=!3m1!4b1?entry=ttu"
                target="_blank" rel="noreferrer" className="flex items-center justify-center gap-5 xl:w-[24.3rem] xl:h-[5.37rem] md:w-[22rem] md:h-[5rem]  w-[16rem] h-[4rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] hover:bg-[#EBF2F8] rounded-2xl px-[1.875rem] py-[0.625rem]">
                <HiOutlineLocationMarker size={24} color="#3b84b4" />
                <div className=" flex flex-wrap sm:w-[18rem] w-[90%] text-center text-[#000000] font-opensans  text-xs lg:text-sm text-[0.6rem]  2xl:text-base font-medium ">
                  Sunrise Towers, Hill no 3, IT SEZ

                  Rushikonda, Visakhapatnam
                </div>
              </a>

            </div>
          </div>
        )
      }
      <div className="w-full flex items-center justify-center ">
        <div className="md:w-[75%] md:h-[9.5rem] w-[80%] flex flex-row md:gap-24 gap-10 items-center justify-center">
          <div className=" md:w-[9rem] md:h-[9rem] w-[9rem] h-[8rem] font-opensans ">
            <img className="md:w-[8.5rem] md:h-[7.5rem]  " src={logoSVG} alt="Quantum Logo" />
          </div>
          <div className="w-[60%]  flex md:flex-row flex-col  md:gap-9 items-start justify-center font-opensans ">
            <div className="w-full flex md:gap-9  items-start justify-center">
              <div className=" p-[0.625rem]  font-opensans">

                <div className="text-[#3b84b4]  font-semibold  2xl:text-xl md:text-lg text-sm leading-loose mb-1.5">Product</div>
                <ul className="text-[#000000] font-medium lg:text-sm 2xl:text-base text-xs">
                  <li onClick={() => {
                    scrollToTop();
                    navigate("/Products")
                  }} className="w-full mb-1 cursor-pointer  hover:text-[#000000]/60">AI Products</li>
                  <li onClick={() => {
                    scrollToTop();
                    navigate("/Products")
                  }} className="mb-1 cursor-pointer hover:text-[#000000]/60">Quantum Products</li>
                  <li onClick={() => {
                    scrollToTop();
                    navigate("/Ourip")
                  }} className="mb-1 cursor-pointer hover:text-[#000000]/60">Our IP's</li>
                </ul>
              </div>
              <div className=" p-[0.625rem] font-opensans">

                <div className="text-[#3b84b4]  font-semibold  2xl:text-xl md:text-lg text-sm leading-loose mb-1.5">Company</div>
                <ul className="text-[#000000] font-medium lg:text-sm 2xl:text-base text-xs">
                  <li onClick={() => {
                    scrollToTop();
                    navigate("/Aboutus")
                  }} className="mb-1 cursor-pointer  hover:text-[#000000]/60">About us</li>
                  <li onClick={() => {
                    scrollToTop();
                    navigate("/Contactus")
                  }} className="mb-1 cursor-pointer hover:text-[#000000]/60">Contact us</li>
                  <li onClick={() => {
                    scrollToTop();
                    navigate("/Resources")
                  }} className="mb-1 cursor-pointer hover:text-[#000000]/60">Resources</li>

                </ul>
              </div>
             
            </div>
            <div className="w-full flex  justify-between items-start gap-10">
                <div className="pl-[0.625rem]">
                  <div className="p-[0.625rem] font-opensans">
                    <div className="text-[#3b84b4]  font-semibold 2xl:text-xl md:text-lg text-sm font-opensans leading-loose mb-1">Contact Us</div>
                    <ul className="text-[#000000]  font-opensans lg:text-sm 2xl:text-base text-xs flex-col">
                      <li className="mb-1 cursor-pointer " >
                        <div className="flex items-center justify-start gap-2.5">
                          <TbMail size={18} color="#3b84b4" />
                          <span className="align-middle">info@thequantum.ai</span>
                        </div>
                      </li>
                      <li className="mb-1 cursor-pointer " >
                        <div className="flex items-center justify-start gap-2.5">
                          <IoCallOutline size={18} color="#3b84b4" />
                          <span className="align-middle">+1 (732) 227 4433</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full border-t-2 border-[#3b84b4]">
        <div className="md:w-[80%] w-[98%] border-white mt-[0.1rem] flex items-center justify-between ">
          <div className="flex w-[50%]">
            <div className="text-[#000000] font-opensans 2xl:text-xs md:text-[0.6rem] text-[0.4rem]">Copyright © 2023 I</div>
            <div className="text-[#3b84b4] font-opensans 2xl:text-xs md:text-[0.6rem] text-[0.4rem]">Quantum AI Global</div>

          </div>
          <div className="flex items-center justify-end w-[50%]">
            <div className="text-[#000000] font-opensans 2xl:text-xs md:text-[0.6rem] text-[0.4rem]">All Rights Reserved</div>
            <div className="text-[#3b84b4] font-opensans 2xl:text-xs md:text-[0.6rem] text-[0.4rem]">| Terms and Conditions | Privacy Policy</div>

          </div>
          <div>

          </div>

        </div>

      </div>
    </div>
  );
}
