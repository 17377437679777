import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Bannersame from '../Components/Bannersame'
import Productscontainer from '../Components/Productscontainer'
import Productsverticalcontainer from '../Components/Productsverticalcontainer'

export default function Products() {
  return (
    <div className="flex flex-col min-h-screen">
    <Header id={3} />
    <div className="flex-1 bg-[#fdfdfd]">
      <Bannersame number={"2"} banner={"Productsban.png"}  head1={"Explore"} head2={"Quantum AI's"} head3={"Cutting-Edge"} head4={"Products"} content={"At Quantum.ai, we value your input, inquiries, and feedback. Our mission is to foster collaboration and innovation, and your communication plays a vital role in achieving that. Whether you have questions about our cutting-edge quantum technologies, want to explore partnership opportunities, or simply want to get in touch, we're here to listen."}/>
    </div>
    <div className='w-full bg-[#fdfdfd] flex flex-col items-center justify-center my-10 gap-6'>
        <div className='flex flex-col items-center justify-center gap-3'>
          <div className='font-opensansbold font-bold text-[#3b84b4] text-2xl 2xl:text-3xl'>We build for future



          </div>
          <div className='sm:w-[70%] w-[80%] font-opensans font-medium text-sm 2xl:text-lg text-[#000000] text-justify'>All our algorithms are designed with a vision of future in quantum space and are scalable, able to solve problems that are too big or too difficult for current technologies.</div>
        </div>
        <div className="w-full h-auto relative flex items-center justify-center px-5  ">
          <Productsverticalcontainer/>
        </div>
      </div>
    <div className='w-full bg-[#fdfdfd] flex flex-col items-center justify-center my-10 gap-6'>
        <div className='flex flex-col items-center justify-center gap-3'>
          <div className='font-opensansbold font-bold text-[#3b84b4] text-2xl 2xl:text-3xl'>Computing

          </div>
          <div className='sm:w-[70%] w-[80%] font-opensans font-medium text-sm 2xl:text-lg text-[#000000] text-justify'>At Quantum Al Global, we offer a range of cutting-edge products and services designed to help businesses harness the full potential of quantum and artificial intelligence technology. Whether you’re looking to optimize your existing processes, improve efficiency, or drive innovation, we have the products and expertise to help you achieve your goals.</div>
        </div>
        <div className="w-full h-auto relative flex items-center justify-center px-5  ">
          <Productscontainer />
        </div>
      </div>
    <Footer showpage={false} />
  </div>
  )
}
