import React, { useState, useRef } from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Bannersame from '../Components/Bannersame'
import emailjs from '@emailjs/browser';
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FaRegClock } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineEmail } from "react-icons/md";

export default function Contactus() {
  const form = useRef();
  const [subject, setSubject] = useState(null);
  const [body, setBody] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm('service_l8j1ytl', 'template_gkb9zeq', form.current, {
      publicKey: 'rcWUEyKg3TYfCLt2-',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <div className="flex flex-col min-h-screen">
      <Header id={2} />
      <div className="flex-1 bg-[#fdfdfd]">
        <Bannersame number={"1"} banner={"Aboutus.png"} head1={"Connect"} head2={"With Us"} head3={""} head4={""} content={"At Quantum.ai, we value your input, inquiries, and feedback. Our mission is to foster collaboration and innovation, and your communication plays a vital role in achieving that. Whether you have questions about our cutting-edge quantum technologies, want to explore partnership opportunities, or simply want to get in touch, we're here to listen."} />
      </div>
      <div className='w-full bg-[#fdfdfd] flex flex-col items-center justify-center my-10 gap-6'>
        <div className='flex flex-col items-center justify-center gap-3'>
          <div className='font-opensansbold font-bold text-[#3b84b4] text-2xl 2xl:text-3xl'>We are Happy to Assist,</div>
          <div className='sm:w-[60%] w-[80%] font-opensans font-medium text-sm 2xl:text-lg text-[#000000] text-justify'>At Quantum AI Global, we are dedicated to helping organizations harness the full potential of quantum and AI technology. If you have any questions or would like to learn more about our products and services, please don’t hesitate to get in touch with us.</div>
        </div>
        <div className="w-full h-auto relative flex sm:flex-row flex-wrap items-center justify-center px-5 py-5 sm:gap-20 gap-5  ">
          <div className='sm:w-[25%] w-[15rem] px-2  border-2 border-gray-100 flex items-center justify-center xl:gap-10 gap-5 h-[7rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef]'>
              <MdOutlineEmail color="#3B84B4" size={40}/>
              <div className='w-[70%]  flex flex-col items-start justify-center'>
              <div className='font-opensans font-normal text-[#3b84b4] text-sm xl:text-lg md:text-[1rem]'>
                E mail
                </div>
                <div className='font-opensans font-normal text-[#000000]  text-xs md:text-sm '>
                info@thequantum.ai
                </div>
              </div>

          </div>
          <div className='sm:w-[25%]  w-[15rem] px-2  border-2 border-gray-100 flex items-center justify-center xl:gap-10 gap-5 h-[7rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef]'>
              <LuPhoneCall  color="#3B84B4" size={40}/>
              <div className='w-[70%]   flex flex-col items-start justify-center'>
              <div className='font-opensans font-normal text-[#3b84b4] text-sm xl:text-lg md:text-[1rem]'>
                Office Phone
                </div>
                <div className='font-opensans font-normal text-[#000000]  text-xs md:text-sm '>
                +1 (732) 227 4433
                </div>
                <div className='font-opensans font-normal text-[#000000]  text-xs md:text-sm '>+91 (812) 102 7006</div>
              </div>

          </div>
          <div className='sm:w-[25%] w-[15rem] px-2 border-2 border-gray-100 flex items-center justify-center xl:gap-10 gap-5 h-[7rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef]'>
              <FaRegClock color="#3B84B4" size={40}/>
              <div className='w-[70%]  flex flex-col items-start justify-center'>
              <div className='font-opensans font-normal text-[#3b84b4] text-sm xl:text-lg md:text-[1rem]'>
              Office Timings
                </div>
                <div className='font-opensans font-normal text-[#000000]  text-xs md:text-sm '>
                Mon – Fri : 9.00am to 6.00pm
                </div>
                <div className='font-opensans font-normal text-[#000000]  text-xs md:text-sm '>
                Sat : 10.00am to 5.00pm
                </div>
                <div className='font-opensans font-normal text-[#000000]  text-xs md:text-sm '>
                Sunday: Holiday
                </div>
              </div>

          </div>
        </div>
      </div>
      <div className={`flex flex-col items-center justify-center w-full  bg-[#FDFDFD] gap-4 mt-5 `}>
      <div className="flex flex-col  gap-4 w-[75%] border-2 border-[#3b84b4] px-[2.25rem] py-[1.25rem] rounded-3xl">
            <div className="text-center text-[#3b84b4] font-opensans font-semibold 2xl:text-2xl md:text-xl text-md">
              Where can you find us?
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center xl:gap-12 gap-6">
              <a href="https://maps.app.goo.gl/hd4Mw2vCMX5STjXz5"
                target="_blank" rel="noreferrer" className="flex items-center justify-center gap-5 xl:w-[24.3rem] xl:h-[5.37rem] md:w-[22rem] md:h-[5rem]  w-[16rem] h-[4rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] hover:bg-[#EBF2F8] rounded-2xl px-[1.875rem] py-[0.625rem]">
                <HiOutlineLocationMarker size={24} color="#3b84b4" />
                <div className="w-[90%] flex flex-col items-center justify-center">
                  <div className=" sm:w-[18rem] text-center w-[90%] text-[#000000] font-opensans   text-xs lg:text-sm text-[0.6rem]  2xl:text-base font-medium ">
                  22 Meridian Rd Unit 14



                  </div>
                  <div className=" sm:w-[18rem] text-center w-[90%]  text-[#000000] font-opensans   text-xs lg:text-sm text-[0.6rem]  2xl:text-base  font-medium ">
                  Edison, NJ 08820



                  </div>
                </div>


              </a>
              <a href="https://maps.app.goo.gl/Y7e6sdXc3U5DjCiD9"
                target="_blank" rel="noreferrer" className="flex items-center justify-center gap-5 xl:w-[24.3rem] xl:h-[5.37rem] md:w-[22rem] md:h-[5rem]  w-[16rem] h-[4rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] hover:bg-[#EBF2F8] rounded-2xl px-[1.875rem] py-[0.625rem]">
                <HiOutlineLocationMarker size={24} color="#3b84b4" />
                <div className="w-[90%] flex flex-wrap sm:w-[18rem]  text-center text-[#000000] font-opensans text-xs lg:text-sm text-[0.6rem]  2xl:text-base font-medium">
                  Suite 305B, Capital Park,

                  HITEC City Hyderabad, Telangana 500081
                </div>
              </a>

            </div>
            <div className="flex flex-col md:flex-row items-center justify-center xl:gap-12 gap-6">
              <a href="https://www.google.com/maps/search/USA+Qulabz+Inc+16192+Coastal+Highway+Lewes+Delaware,+19958/@38.782478,-75.2334244,12z/data=!3m1!4b1?entry=ttu"
                target="_blank" rel="noreferrer" className="flex items-center justify-center gap-5 xl:w-[24.3rem] xl:h-[5.37rem] md:w-[22rem] md:h-[5rem]  w-[16rem] h-[4rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] hover:bg-[#EBF2F8] rounded-2xl px-[1.875rem] py-[0.625rem]">
                <HiOutlineLocationMarker size={24} color="#3b84b4" />
                <div className="w-[90%] flex flex-col items-center justify-center">
                  <div className=" sm:w-[18rem] text-center w-[90%] text-[#000000] font-opensans   text-xs lg:text-sm text-[0.6rem]  2xl:text-base font-medium ">
                    FDRK2335, Compass Building


                  </div>
                  <div className=" sm:w-[18rem] text-center w-[90%] text-[#000000] font-opensans   text-xs lg:text-sm text-[0.6rem]  2xl:text-base  font-medium ">
                    Ras Al Khaimah, UAE


                  </div>
                </div>


              </a>
              <a href="https://www.google.com/maps/search/USA+Qulabz+Inc+16192+Coastal+Highway+Lewes+Delaware,+19958/@38.782478,-75.2334244,12z/data=!3m1!4b1?entry=ttu"
                target="_blank" rel="noreferrer" className="flex items-center justify-center gap-5 xl:w-[24.3rem] xl:h-[5.37rem] md:w-[22rem] md:h-[5rem]  w-[16rem] h-[4rem] bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] hover:bg-[#EBF2F8] rounded-2xl px-[1.875rem] py-[0.625rem]">
                <HiOutlineLocationMarker size={24} color="#3b84b4" />
                <div className=" flex flex-wrap sm:w-[18rem] w-[90%] text-center text-[#000000] font-opensans  text-xs lg:text-sm text-[0.6rem]  2xl:text-base font-medium ">
                  Sunrise Towers, Hill no 3, IT SEZ

                  Rushikonda, Visakhapatnam
                </div>
              </a>

            </div>
          </div>
      </div>
      <div className="2xl:w-[70%] md:w-[90%] border-2 border-gray-100 mx-auto flex md:flex-row flex-col md:gap-0 gap-8 items-center justify-center  px-10 py-5 bg-[#fdfdfd] ">
      <div className=" px-10 py-10 bg-[#fdfdfd] border-2 border-gray-100  shadow-lg rounded-xl  shadow-[#d5e5ef] ">
          <h2 className="w-full text-3xl 2xl:text-4xl font-opensansextrabold font-extrabold mb-1 wordGradient text-center">
            Just say Hello
          </h2>
          <div className='font-opensans font-normal w-full text-[#3b84b4] text-sm 2xl:text-lg mb-1 text-center'>
            Let us know more about you
          </div>
          <form ref={form} onSubmit={sendEmail} >
            <div className="md:mb-4 mb-1 flex text-sm items-center justify-center gap-2">

              <input
                type="text"
                id="name"
                name="user_name"
                placeholder='Name'
                className="mt-1 md:p-2 w-full border font-opensans font-medium text-[#000000] border-[#3b84b4] rounded-md bg-[#fdfdfd]  "
                required
              />
              <input
                type="tel"
                id="phoneNumber"
                name="user_phoneNumber"
                placeholder='Phone number'

                className="mt-1 md:p-2 w-full border font-opensans font-medium text-[#000000] border-[#3b84b4] rounded-md bg-[#fdfdfd] "
                required
              />
            </div>



            <div className="md:mb-4 text-sm mb-1">

              <input
                type="email"
                id="email"
                name="user_email"
                placeholder='Email'
                onChange={(e) => setSubject(e.target.value)}
                className="mt-1 md:p-2 w-full border font-opensans font-medium text-[#000000] border-[#3b84b4] rounded-md bg-[#fdfdfd]  "
                required
              />
            </div>

            <div className="md:mb-4 text-sm mb-1">

              <textarea onChange={(e) => setBody(e.target.value)}
                id="message"
                name="user_message"
                placeholder='Message'

                rows="4"
                className="mt-1 md:p-2 w-full border font-opensans font-medium text-[#000000] border-[#3b84b4] rounded-md bg-[#fdfdfd]  "
                required
              ></textarea>
            </div>



            <button
              type="submit"
              className="w-full bg-[#fdfdfd] shadow-lg shadow-[#d5e5ef] text-[#000000] font-normal text-xs 2xl:text-base  md:px-4 md:py-2 px-2 py-2 rounded-md hover:bg-[#fdfdfd]/30"
            >
              Send a message
            </button>

          </form>
        </div>
      </div>
      <Footer showpage={false} />
    </div>
  )
}
